import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import DataTable from '../../DataTable'
import UseAPI, { SUCCESS, API_GET_CUSTOMERS } from "../../../api";
import { truncatedString } from "../../../util/string";
import { useDebounce } from "../../../util/debounce";
const CustomerSearch = (props) => {
  const [customers, setCustomers] = useState([])
  const [{ status, response }, makeRequest] = UseAPI();
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalDate] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [fieldColumns, setFieldColumns] = useState([])
  const [customFieldColumns, setCustomFieldColumns] = useState([])

  const handleSearch = useDebounce((searchText) => {
    makeRequest(API_GET_CUSTOMERS, { branch_ids: props.branch?.id ?? undefined, phone: searchText, id_no: searchText, code: searchText, page: page, limit: limit })
    setLoading(true)
  }, 1000)

  const togglemodal = () => {
    props.onClose()
  }

  useEffect(() => {
    makeRequest(API_GET_CUSTOMERS, { branch_ids: props.branch?.id ?? undefined, page: page, limit: limit })
    setLoading(true)
  }, [])

  useEffect(() => {
    if (status === SUCCESS) {
      if (response.customers) {
        setCustomers([...customers, ...response.customers.data])
        setCustomers(response.customers.data)
        setFieldColumns(response.customers.columns)
        setTotalPage(response.customers.pageCount);
        setPage(response.customers.page);
        setTotalDate(response.customers.total);
        setLoading(false)
      }
    }
  }, [status, response])


  const getRows = () => {
    return customers.map((customer, i) => {
      const row = Object.assign({}, customer);
      row["name"] = customer.other_name
      row["customerTypeGroup"] = props.t(customer.customer_type)
      row["idNo"] = truncatedString(customer.id_no, 19)
      row["phone"] = customer.phone
      row.data = customer
      return row
    })
  }
  const data = {
    columns: [

      {
        label:
          props.t('Customer Name'),
        field: "name",
        sort: "asc",
        searchable: true
      },
      {
        label:
          props.t('Customer Type'),
        field: "customerTypeGroup",
        sort: "asc",
        searchable: true
      },
      {
        label: props.t('idNo'),
        field: "idNo",
        sort: "asc",
        searchable: true
      },
      {
        label: props.t('Phone'),
        field: "phone",
        sort: "asc",
        searchable: true
      },
    ],
    rows: getRows()
  };
  return (
    <React.Fragment>
      <Modal isOpen={props.modal} role="dialog" autoFocus={true} centered={true} className="searchModal" tabIndex="-1" toggle={togglemodal}>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {props.t('Search Customer')}
          </ModalHeader >
          <ModalBody>
            <Alert
              color="success"
              className="alert-success fade show  mb-4 pt-2"
              role="alert"
            >
              <i className="far fa-smile mr-2"></i>{props.t('Can show the assigned branch customer only.')}
              <a href={`/customers`}
                className="ml-4 font-size-12 font-italic font-weight-light secondary text-decoration-underline">
                <i className="mdi mdi-alert"></i>
                {props.t("Direct to Customer Page")}
              </a>


            </Alert>
            <DataTable
              responsive
              bordered striped
              displayEntries={false}
              info={false}
              noRecordsFoundLabel={props.t('No Records')}
              data={data}
              loading={loading}
              activePage={Number(page)}
              totalPage={Number(totalPage)}
              totalData={Number(totalData)}
              
              entitiesCount={Number(limit)}
              onEntitiesCountChange={(limit) => {
                setLimit(limit)
                makeRequest(API_GET_CUSTOMERS, { branch_ids: props.branch?.id ?? undefined, page: 0, limit: limit });
                setLoading(true)
              }}

              onRowClick={row => {
                props.onSearched(row.data)
              }}
              onPage={
                ((page) => {
                  makeRequest(API_GET_CUSTOMERS, { branch_ids: props.branch?.id ?? undefined, page: page, limit: limit });
                  setLoading(true)
                })
              }
            />
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
}
export default withTranslation()(CustomerSearch);