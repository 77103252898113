import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import { Offsymbol, OnSymbol } from "../../Switch/switchButton"
import Switch from "react-switch";
import { BxButton } from "../../Button"
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "./storage.scss";
// Redux

import { withRouter } from "react-router-dom";

import {
  useGlobalModalContext,
  MODAL_TYPES,
} from "../../../context/GlobalModalContext";

import { useBranchContext } from "../../../context/BranchProviderContext";
import { useStorageContext } from "../../../context/StorageProviderContext";
import UseAPI, { SUCCESS, API_RELEASE_STORAGE } from "../../../api";

const StorageRelease = (props) => {
  const { contextStorage } =
    useStorageContext();

  const { showModal } = useGlobalModalContext();
  const [remarks, setRemarks] = useState("");
  const [penaltyRequire, setPenaltyRequire] = useState(false);
  const [penalty, setPenalty] = useState(0);
  const [expireDays, setExpireDays] = useState(moment().diff(moment.unix(props.order?.actual_end_date), "days") ?? 0);
  const [{ status, response }, makeRequest] = UseAPI();
  const [depositRefundRequire, setDepositRefundRequire] = useState(false);
  const [depositRefundPrice, setDepositRefundPrice] = useState(props.depositOrder?.final_price)
  const { contextBranch } = useBranchContext();

  const togglemodal = () => {
    setRemarks("");
    props.onClose();
  };

  useEffect(() => {
    if (status === SUCCESS) {
      if (response.storage) {
        props.onSuccess(response.storage);
      }
    }
  }, [status, response])

  const submitRelease = () => {
    if (!props.customer) {
      return;
    }
    showModal(MODAL_TYPES.ACTION_MODAL, {
      title: props.t('Are you Confirm?'),
      message: props.t(`The Release cannot resume`),
      onConfirm: () => {
        showModal(MODAL_TYPES.REMOVAL_ACTION_MODAL, {
          title: props.t('Confirm to release'),
          onConfirm: () => {
            makeRequest(API_RELEASE_STORAGE, contextBranch?.id, contextStorage?.id, {
              remarks: remarks,
              expired_days: Number(expireDays),
              penalty_amount: Number(penalty),
              penalty_from: Number(props.order?.actual_end_date),
              penalty_to: moment().startOf('day').unix(),
              deposit_refund: depositRefundRequire ? Number(depositRefundPrice) : undefined,
            });
          },
        });
      },
    });
  };

  useEffect(() => {
    if (props.order) {
      setRemarks(props.order ? props.order.remarks : '');
    }
  }, [props.order])


  return !props.order ? null : (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className=""
        tabIndex="-1"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>{props.t('Release Storage')}</ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <AvForm action="#" onSubmit={submitRelease}>
                  <Row>
                    <Col xs="6">
                      <Label htmlFor="validationCustomName">{props.t('Customer')}</Label>
                      <p>
                        {props.customer
                          ? props.customer.other_name
                          : ""}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card outline color="dark" className="border">
                        <CardBody>
                          <Row>
                            <Col xs="12">
                              <Label>{props.t('Storage')} </Label>
                            </Col>
                            <Col xs="12">
                              <p className="">
                                {contextStorage?.code}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="">
                              <Label>{props.t('Order End Date')} </Label>
                              <p className="">
                                {props.order.actual_end_date
                                  ? moment
                                    .unix(props.order.actual_end_date)
                                    .format("YYYY/MM/DD")
                                  : ""}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="">
                              <FormGroup
                                className="expireDays"
                              >
                                <Label htmlFor="validationRentPeriod">{props.t('Over due Days')}</Label>
                                <InputGroup>
                                  <AvField
                                    name="expireDays"
                                    type="number"
                                    value={expireDays}
                                    onChange={(e) => {
                                      setExpireDays(e.target.value)
                                    }}
                                    errorMessage={props.t('Min Value 1')}
                                    id="validationExpireDays"
                                    validate={{
                                      required: { value: true },
                                      min: { value: 1 },
                                    }}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col xs="12" className="">
                              <Label>{props.t("Penalty Require")}</Label>
                              <Switch
                                uncheckedIcon={<Offsymbol label={props.t('No')} />}
                                className="d-flex mr-1 mb-4 xs12"
                                checkedIcon={<OnSymbol label={props.t('Yes')} />}
                                onColor="#626ed4"
                                onChange={(checked) => {
                                  setPenaltyRequire(checked)
                                }
                                }
                                checked={penaltyRequire}
                              />
                              <FormGroup hidden={!penaltyRequire}>
                                <Label>{props.t('Penalty')}</Label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                  </div>
                                  <Input
                                    type="number"
                                    maxLength="8"
                                    name="penalty"
                                    onChange={(e) => setPenalty(e.target.value)}
                                    value={penalty}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs="12" className="" hidden={!props.depositOrder || props.depositOrder?.status !== "COMPLETED"}>
                              <Label>{props.t("Deposit Refund")}</Label>
                              <Switch
                                uncheckedIcon={<Offsymbol label={props.t('No')} />}
                                className="d-flex mr-1  xs12"
                                checkedIcon={<OnSymbol label={props.t('Yes')} />}
                                onColor="#626ed4"
                                onChange={(checked) => {
                                  setDepositRefundRequire(checked)
                                }
                                }
                                checked={depositRefundRequire}
                              />
                              <FormGroup
                                hidden={!depositRefundRequire}
                              >
                                <Label className="d-flex xs12">{props.t('Refund Deposit')} </Label>
                                <span className="font-size-9 text-secondary xs12">{props.t("Total deposit")}:{props.depositOrder?.final_price}</span>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                  </div>
                                  <Input
                                    type="number"
                                    maxLength="8"
                                    name="depositRefundPrice"
                                    onChange={(e) => setDepositRefundPrice(e.target.value)}
                                    value={depositRefundPrice}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="mt-2">
                              <FormGroup className="">
                                <Label>{props.t('Remarks')}</Label>
                                <Input
                                  type="textarea"
                                  maxLength="500"
                                  name="remarks"
                                  onChange={(e) => setRemarks(e.target.value)}
                                  value={remarks}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <BxButton type="submit" color="success">
                    {props.t('Submit')}
                  </BxButton>
                  <Button type="button" color="secondary" onClick={togglemodal}>
                    {props.t('Close')}
                  </Button>
                </AvForm>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(StorageRelease));
