
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, FormGroup, InputGroup, Label, Button } from "reactstrap";

import "./columnFilter.scss"

const ColumnFilter = (props) => {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (props.columns) {
      setColumns(props.columns)
    }
  }, [props.columns])

  return (
    <Card>
      <CardBody>
        <Row className='mb-4'>
          <Col>
            <CardTitle>{props.title} </CardTitle>
          </Col>
        </Row>
        <Row>
          {columns?.map((col, i) => {
            return (
              <Col xs={3} key={i} >
                <div className="form-check mb-3">
                  <input className="form-check-input font-size-14 largerCheckbox" type="checkbox"
                    checked={col.enable ?? false}
                    onChange={(e) => {
                      props.onFilter(col.field, e.target.value)
                    }} />
                  <label className="form-check-label font-size-14" htmlFor="defaultCheck1">
                    {col.label}
                  </label>
                </div>
              </Col>
            )
          })}
        </Row>
      </CardBody>
    </Card>
  );
}

export default (ColumnFilter);