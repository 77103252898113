import React, { useEffect, useState } from "react";
// import { MDBDataTableV5 } from "mdbreact";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Spinners from "../../components/Spinners";
import "./datatables.scss";
import { withTranslation } from "react-i18next";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ColumnFilter from "./ColumnFilter";
import Select from "react-select";
import {
  Collapse,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  FormGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
} from "reactstrap";
import { BxButton } from "../Button";
const DataTable = (props) => {
  const [columnStatus, setColumnStatus] = useState([]);
  const [searchText, setSearchText] = useState();
  const paginationEnable = props.pagination === false ? false : true;
  const [collapse, setCollapse] = useState(true);
  const columns = props.columnfilter
    ? columnStatus?.filter((col) => col.enable === true)
    : props.data?.columns;
  // const [columns, setColumns] = useState()
  // useEffect(() => {
  //   setColumns(
  //     props.columnfilter
  //       ? columnStatus?.filter((col) => col.enable === true)
  //       : props.data?.columns
  //   )
  // }, [props.columnfilter, props.data?.columns])

  const getPagination = (activePage) => {
    if (props.totalPage < 5) {
      return Array.from({ length: props.totalPage }, (_, i) => i + 1);
    } else {
      if (activePage >= 5) {
        if (props.totalPage - activePage < 5) {
          return Array.from({ length: 5 }, (_, i) => i + (props.totalPage - 4));
        }
        const start = Array.from({ length: 2 }, (_, i) => i + activePage - 2);
        const end = Array.from({ length: 2 }, (_, i) => i + activePage + 1);
        // props.totalPage+1 - props.activePage
        return [...start, activePage, ...end];
      } else {
        const start = Array.from({ length: activePage }, (_, i) => i + 1);
        const end = Array.from(
          { length: 5 - start.length },
          (_, i) => i + activePage + 1
        );
        return [...start, ...end];
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem(props.id)) {
      const filter = JSON.parse(localStorage.getItem(props.id) ?? "{}");
      setColumnStatus(
        props.data.columns?.map((col) => {
          try {
            const key = Object.keys(filter)?.find((f) => f === col.field);
            col.enable = filter[key];
          } catch (e) {
            col.enable = true;
          }
          return col;
        })
      );
    } else {
      const defaultFilter = props.data.columns?.map((col) => {
        col.enable = true;
        return col;
      });
      setColumnStatus(defaultFilter);
    }
  }, [props.data.columns]);

  useEffect(() => {
    if (columnStatus && columnStatus.length > 4) {
      const filter = {};
      columnStatus.forEach((col) => {
        filter[col.field] = !!col.enable;
      });

      localStorage.setItem(props.id, JSON.stringify(filter));
    }
  }, [columnStatus]);

  const toCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <React.Fragment>
      {props.columnfilter && (
        <Row className="">
          <Col sm="4">
            <Button
              outline
              className="btn waves-effect waves-light btn-sm "
              onClick={toCollapse}
              style={{ cursor: "pointer" }}
            >
              <i
                className={`${collapse ? "bx bxs-down-arrow" : "bx bxs-up-arrow"
                  } font-size-16 align-middle mr-2`}
              />
              {props.columnfilter?.label}
            </Button>
          </Col>
          <Col xs={8}></Col>
          <Collapse isOpen={!collapse} className="mb-4">
            <ColumnFilter
              title={props.columnfilter?.label}
              columns={columnStatus}
              onFilter={(k, v) => {
                setColumnStatus(
                  columnStatus.map((col) => {
                    if (col.field === k) {
                      col.enable = !col.enable;
                      let filter = {};
                      if (props.id) {
                        try {
                          filter = JSON.parse(
                            localStorage.getItem(props.id) ?? "{}"
                          );
                        } catch (e) {
                          filter = {};
                        }
                        filter[k] = col.enable;
                      }
                    }
                    return col;
                  })
                );
              }}
            />
            {props.render && props.render()}
          </Collapse>
        </Row>
      )}
      {!!props.loading && (
        <div className={`loading-container overlay-over`}>
          <Spinners
            className="vertical-center"
            loading={!!props.loading}
          ></Spinners>
        </div>
      )}
      {props.children}
      <Row className="mb-2">
        <Col></Col>
        {props.onSearch && !props.loading && (
          <Col xs={12} md={4}>
            <div className="text-sm-right">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="bx bx-search-alt font-size-16 align-middle"></i>
                  </span>
                </div>
                <input
                  name="search"
                  autoComplete="off"
                  placeholder={props.t("Search")}
                  type="search"
                  className="form-control"
                  value={searchText ?? ""}
                  onChange={async (e) => {
                    setSearchText(e.target.value);
                    if (e.target.value?.length > 0) {
                      if (props.onSearch) props.onSearch(e.target.value);
                    } else {
                      if (props.onClear) props.onClear();
                    }
                  }}
                  onClose={() => console.log(true)}
                />
                {/* <BxButton color={"primary"} onClick={() => {
                  if (props.onSearch) {
                    props.onSearch(searchText)
                  }
                }}>{props.t("Search")}</BxButton> */}
              </div>
            </div>
          </Col>
        )}
      </Row>
      <div className="table-responsive ">
        <Table className="table table-striped table-bordered">
          <Thead>
            <Tr>
              {columns?.map((col, j) => {
                return (
                  <Th key={j}>
                    {col.label}
                    {col.sortable &&
                      (col.sort === "asc" ? (
                        <i
                          className="bx bx-up-arrow-alt"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (props.onSort) {
                              props.onSort(col.sortField, "desc");
                            }
                          }}
                        />
                      ) : (
                        <i
                          className="bx bx-down-arrow-alt"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (props.onSort) {
                              props.onSort(col.sortField, "asc");
                            }
                          }}
                        />
                      ))}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          {props.data.rows?.length === 0 && !!!props.loading ? (
            <Tbody>
              <Tr>
                <Td>{props.noRecordsFoundLabel}</Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody>
              {props.data?.rows?.map((row, i) => {
                return (
                  <Tr key={i}>
                    {columns?.map((col, j) => {
                      return (
                        <Td
                          key={j}
                          onClick={() => {
                            if (props.onRowClick) {
                              props.onRowClick(row);
                            }
                          }}
                        >
                          {Array.isArray(row[col?.field]) ? props.t("N/A") : row[col?.field]}

                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>

        <Row>
          {paginationEnable && <Col>
            <Pagination
              aria-label="Page navigation example"
              listClassName="justify-content-start"
            >
              <PaginationItem disabled={props.activePage < 5}>
                <PaginationLink
                  tabIndex="-1"
                  onClick={() => {
                    props.onPage(0);
                  }}
                >
                  <i className="mdi mdi-chevron-left" />
                </PaginationLink>
              </PaginationItem>
              {getPagination(props.activePage)?.map((page, i) => {
                return (
                  !isNaN(page) &&
                  props.activePage && (
                    <PaginationItem
                      key={i}
                      active={page === props.activePage}
                      onClick={() => {
                        props.onPage(page);
                      }}
                    >
                      <PaginationLink>{page}</PaginationLink>
                    </PaginationItem>
                  )
                );
              })}
              <PaginationItem disabled={props.totalPage - props.activePage < 5}>
                <PaginationLink
                  onClick={() => {
                    props.onPage(props.totalPage);
                  }}
                >
                  <i className="mdi mdi-chevron-right" />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>}
          {paginationEnable && <Col>

            <div className="text-sm-right">
              <Row>
                <Col></Col>
                <Col xs={3}>

                  <Select
                    className="text-sm-right"
                    value={{
                      label: props.entitiesCount ?? 10,
                      value: props.entitiesCount ?? 10,
                    }}
                    onChange={(e) => {
                      props.onEntitiesCountChange(e?.value);
                    }}
                    menuPlacement="auto"
                    isMulti={false}
                    options={[
                      {
                        options: [
                          { label: 10, value: 10 },
                          { label: 20, value: 20 },
                          { label: 50, value: 50 },
                          { label: 100, value: 100 },
                          { label: 500, value: 500 },
                          { label: 1000, value: 1000 },
                        ],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </Col>
                <Col xs={4}>
                  {` ${props.t("out of Total")}: ${props.totalData
                    }`}
                </Col>

              </Row>
            </div>
          </Col>}
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(DataTable);
