import { min } from "lodash"

export const second = 1
export const minute = 60 * second
export const hour = 60 * minute
export const day = 24 * hour
export const week = 7 * day
export default {
    second: second,
    minute: minute,
    hour: hour,
    day: day,
    week: week
}