
import axios from 'axios';
import { BoxappBackend } from './boxapp_helper'

class BoxappInvoiceBackend extends BoxappBackend {

  /**
  /**
   * 
   * @param {*} error 
   * @returns 
   */

  createInvoice = async (branchID, id, param) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/invoice`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: param
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      console.log(err)
      throw err;
    }
  };

  getInvoices = async (filter) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/invoices",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: filter
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getInvoice = async (branchID, id, invoiceID) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/invoice/${invoiceID}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {}
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  cancelInvoice = async (branchID, id, invoiceID) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/invoice/${invoiceID}/cancel`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {}
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  voidInvoice = async (branchID, id, invoiceID) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/invoice/${invoiceID}/void`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {}
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  updatePayment = async (branchID, id, invoiceID, paymentID, payment) => {
    try {
      const response = await axios({
        method: 'put',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/invoice/${invoiceID}/payment/${paymentID}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: payment
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  voidPayment = async (branchID, id, invoiceID, paymentID) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/invoice/${invoiceID}/payment/${paymentID}/void`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappInvoiceBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappInvoiceBackend = () => {
  if (!_BoxappInvoiceBackend) {
    _BoxappInvoiceBackend = new BoxappInvoiceBackend();
  }
  return _BoxappInvoiceBackend;
};

export { getBoxappInvoiceBackend };
