import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useGlobalModalContext } from "../../context/GlobalModalContext";

import { withTranslation } from 'react-i18next';
const MessageModal = (props) => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, messageType, message } = modalProps || {};

  const handleModalToggle = () => {
    if (props.onConfirm) {
      props.onConfirm()
    }
    hideModal();
  };
  return (
    <SweetAlert
      title={props.t(title)}
      success={messageType === "success"}
      show={true}
      confirmBtnText={props.t("OK")}
      cancelBtnText={props.t("CANCEL")}
      onConfirm={handleModalToggle}
    >
      {message ? props.t(message) : ''}
    </SweetAlert >
  );
};

export default withTranslation()(MessageModal);
