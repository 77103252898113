
export const getStorageFromOrder = (order) => {
    return order?.invoices?.find(invoice => invoice.paid_items?.find((paid_item) => paid_item.item_type === 'STORAGE'))?.paid_items?.find((paid_item) => paid_item.item_type === "STORAGE") ?? null
}
export const getInvoicePaidItemsAmount = (invoice) => {
    return invoice?.paid_items?.reduce((a, b) => a + b.final_price, 0);
}
export const getInvoicePaidItemsNonRepayAmount = (invoice) => {
    return invoice?.paid_items?.filter((paid_item) => paid_item?.item_type !== "REPAY")?.reduce((a, b) => a + b.final_price, 0);
}


export const getAllInvoicesPayments = (order, invoiceType, status) => {

    return order?.invoices?.filter((invoice) => invoice?.invoice_type === invoiceType)?.filter((invoice) => invoice?.status === status)
}


export const getPaymentItems = (invoice) => {
    const result = [];
    invoice?.payments?.filter((payment) => payment?.status !== "VOID")
        ?.flatMap((payment) => payment.payment_items)
        ?.reduce(function (res, value) {

            const paidItem = invoice?.paid_items?.find((paid_item) => paid_item?.id === value?.paid_item_id)
            const itemId = paidItem?.item_id
            if (!res[itemId]) {
                res[itemId] = {
                    ...value, id: value?.id,
                    amount: 0,
                    paid_item: invoice?.paid_items?.find((paid_item) => paid_item?.id === value?.paid_item_id)
                };
                result.push(res[itemId]);
            }
            res[itemId].amount += value?.amount;
            return res;
        }, {});
    return result
}
// create function to get all paid items from invoice
export const getAllPaidItemsFromInvoice = (invoice) => {
    return invoice?.paid_items?.map((item) => {
        const paymentItems = invoice?.payments?.filter((payment) => payment?.status !== "VOID")?.map(
            (payment) => payment?.payment_items
        )?.flat()?.filter(
            (payment_item) => payment_item?.paid_item_id === item?.id
        )

        return {
            ...item,
            invoice_type: invoice?.invoice_type,
            status: invoice?.status,
            amount: paymentItems?.reduce((a, b) => a + b?.amount, 0)

        }
    })
}

export const getAllPaymentInvoice = (order, status) => {
    return getAllInvoicesPayments(order, "Payment", status)?.flatMap((invoice) => {
        return getPaymentItems(invoice)
    })

    // return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Payment")
}
// export const getAllRefundInvoice = (order) => {
//     return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Refund")
// }
export const getAllPaidItemsFromOrder = (order, filter) => {
    let invoices = order?.invoices ?? []
    const invoiceTypesIn = filter?.invoiceType?.in ?? []
    const invoiceTypesNotIn = filter?.invoiceType?.notIn ?? []
    const paymentStatusIn = filter?.payment?.status?.in ?? []
    const paymentStatusNotIn = filter?.payment?.status?.notIn ?? []
    if (invoiceTypesIn.length) {
        invoices = invoices.filter((invoice) => invoiceTypesIn.includes(invoice.invoice_type))
    }
    if (invoiceTypesNotIn.length) {
        invoices = invoices.filter((invoice) => !invoiceTypesNotIn.includes(invoice.invoice_type))
    }
    if (paymentStatusIn.length) {
        invoices = invoices.filter((invoice) => paymentStatusIn.includes(invoice.payment?.status))
    }
    if (paymentStatusNotIn.length) {
        invoices = invoices.filter((invoice) => !paymentStatusNotIn.includes(invoice.payment?.status))
    }
    return invoices.map((invoice) => invoice?.paid_items.map((item) => {
        item.invoice_type = invoice?.invoice_type
        item.status = invoice?.status
        return item
    })).flat()
}


export const getAllCompletedPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Payment" && invoice?.status === "COMPLETED").map((invoice) => invoice?.paid_items.map((item) => {
        item.invoice_type = invoice?.invoice_type
        item.status = invoice?.status
        return item
    })).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}

export const getAllCompletedRefundPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Refund" && invoice?.status === "COMPLETED").map((invoice) => invoice?.paid_items.map((item) => {
        item.invoice_type = invoice?.invoice_type
        item.status = invoice?.status
        return item
    })).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}

export const getAllCompletedPaymentRefundPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status === "COMPLETED").map((invoice) => invoice?.paid_items.map((item) => {
        item.invoice_type = invoice?.invoice_type
        item.status = invoice?.status
        return item
    })).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}






export const getAllNonCancelledPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Payment" && invoice?.status !== "CANCELLED" && invoice?.status !== "VOID" && invoice?.item_type !== "WALLET").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    )
        ?.flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)

}

export const getAllNonCancelledRefundPaidItems = (order) => {
    return order?.invoices?.filter((invoice) =>
        invoice?.invoice_type === "Refund" && invoice?.status !== "CANCELLED" && invoice?.status !== "VOID" && invoice?.item_type !== "WALLET"
    ).map((invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}

export const getAllNonCancelledPaymentRefundPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status !== "CANCELLED" && invoice?.status !== "VOID").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}


export const getAllNonCancelledPaymentRefundPaidItemsNonWalletInvoice = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status !== "CANCELLED" && invoice?.status !== "VOID" && invoice.invoice_type !== "Wallet").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}


export const getAllNonCancelledNonRepayPaymentRefundPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice.invoice_type !== "Wallet" && invoice?.status !== "CANCELLED" && invoice?.status !== "VOID").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}

export const getAllVoidPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Payment" && invoice?.status === "VOID").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}

export const getAllVoidedRefundPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Refund" && invoice?.status === "VOID").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}



export const getAllCancelledPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Payment" && invoice?.status === "CANCELLED").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}

export const getAllCancelledRefundPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Refund" && invoice?.status === "CANCELLED").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}

export const getAllCancelledPaymentRefundPaidItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status === "CANCELLED").map(
        (invoice) => getAllPaidItemsFromInvoice(invoice)
    ).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}







export const getAllCompletedInvoicePayment = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status === "COMPLETED" && invoice.invoice_type === "Payment").map((invoice) => invoice?.payments)?.flat()
}
export const getAllCompletedRefundInvoicePayment = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status === "COMPLETED" && invoice.invoice_type === "Refund").map((invoice) => invoice?.payments)?.flat()
}
export const getAllCompletedPaymentRefundInvoicePayment = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status === "COMPLETED").map((invoice) => invoice?.payments)?.flat()
}



export const getAllInvoiceTransaction = (order) => {
    return order?.invoices?.flatMap((invoice) => invoice?.transactions)
}

export const getAllInvoiceDepositTransaction = (order) => {

    return order?.invoices?.filter((invoice) => invoice?.transactions?.find((transaction) => transaction?.transaction_type === "Deposit"))?.map((invoice) => invoice?.transactions).flat()
}

export const getAllInvoicePaymentTransaction = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.transactions?.find((transaction) => transaction?.transaction_type === "Payment"))?.map((invoice) => invoice?.transactions).flat()
}

export const getAllInvoiceTransactionPrepaid = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.transactions?.find((transaction) => transaction?.transaction_type === "Prepaid"))?.map((invoice) => invoice?.transactions).flat()
}


export const getAllInvoiceLoanTransaction = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.transactions?.find((transaction) => transaction?.transaction_type === "Loan"))?.map((invoice) => invoice?.transactions).flat()
}

export const getAllInvoiceRepayTransaction = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.transactions?.find((transaction) => transaction?.transaction_type === "Repay"))?.map((invoice) => invoice?.transactions).flat()
}



export const getAllInvoiceRepayLoanTransaction = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.transactions?.filter((transaction) => transaction.transaction_type === "Repay" && transaction.transaction_type === "Loan"))?.map((invoice) => invoice?.transactions).flat()
}

export const getAllCompletedTransaction = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status === "COMPLETED")?.map((invoice) => invoice?.transactions).flat()
}


export const getAllNonCancelledTransaction = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.status !== "CANCELLED" && invoice?.status !== "VOID")?.flatMap((invoice) => invoice?.transactions)
}




export const getAllNonCancelledRepayItems = (order) => {

    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Wallet" && invoice?.status !== "CANCELLED" && invoice?.status !== "VOID")?.map((invoice) => invoice?.paid_items.map((item) => {
        item.invoice_type = invoice?.invoice_type
        item.status = invoice?.status
        return item
    })).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}


export const getAllCancelledRepayItems = (order) => {
    return order?.invoices?.filter((invoice) => invoice?.invoice_type === "Wallet" && invoice?.status === "CANCELLED")?.map((invoice) => invoice?.paid_items.map((item) => {
        item.invoice_type = invoice?.invoice_type
        item.status = invoice?.status
        return item
    })).flat()?.sort((a, b) => a.item_type !== "STORAGE" ? 1 : -1)
}

