import moment from "moment-timezone";
export const setWithExpiry = (key, value, ttl) => {
  const now = moment(new Date());
  const item = {
    value: value,
    expiry: moment(now).add(ttl, "seconds").unix(),
  };
  localStorage.setItem(key, JSON.stringify(item));
};
export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = moment(new Date()); //todays date
  const end = moment.unix(item.expiry);
  if (moment(end).isBefore(now)) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
export const setWithoutExpiry = (key, value) => {
  if (!key) {
    return;
  }
  const item = {
    value: value,
  };
  localStorage.setItem(key, JSON.stringify(item));
};
export const getWithoutExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);

  return item.value;
};
export const removeItem = (key) => {
  localStorage.removeItem(key);
};
export default {
  setWithExpiry,
  getWithExpiry,
  setWithoutExpiry,
  getWithoutExpiry,
  removeItem,
};
