import React, { createContext, useContext, useState, useEffect } from "react";

import CustomerSearchModal from "../components/Modal/Customers/CustomerSearch";

export const CUSTOMER_DIALOG_TYPES = {
  SEARCH_MODAL: "SEARCH_CUSTOMER_MODAL",
};

const DIALOG_COMPONENTS = {
  [CUSTOMER_DIALOG_TYPES.SEARCH_MODAL]: CustomerSearchModal,
};

const initalState = {
  showModal: () => { },
  hideModal: () => { },
  store: {},
};
export const CustomerContext = createContext(initalState);
export const useCustomerContext = () => useContext(CustomerContext);
// This context provider is passed to any component requiring the context
const CustomerProvider = ({ children }) => {
  //api helper  

  const [store, setStore] = useState();
  const { modalType, modalProps, modalShow } = store || {};

  const showDialog = (modalType, modalProps = {}) => {
    setStore({
      ...store,
      modalType: modalType,
      modalProps: modalProps,
      modalShow: true,
    });
  };

  const hideDialog = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
      modalShow: false,
    });
  };

  const renderComponent = () => {
    const ModalComponent = DIALOG_COMPONENTS[modalType];

    if (!modalType || !ModalComponent) {
      return null;
    }

    return <ModalComponent
      id="global-modal"
      {...modalProps}
      modal={modalShow} />;
  };


  return (
    <CustomerContext.Provider
      value={{
        store, showDialog, hideDialog,

        // fetchStorages,
      }}
    >
      {renderComponent()}
      {children}
    </CustomerContext.Provider>
  );
};
export default CustomerProvider;