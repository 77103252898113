import React, { createContext, useContext, useState, useEffect, useMemo } from "react";

import StorageRentModal from "../components/Modal/Storages/StorageRent";
import StoragePaymentInfoModal from "../components/Modal/Storages/StoragePaymentInfo";
import StorageSearchModal from "../components/Modal/Storages/StorageSearch";
import StorageTerminateModal from "../components/Modal/Storages/StorageTerminate";
import StorageReleaseModal from "../components/Modal/Storages/StorageRelease";


export const STORAGE_DIALOG_TYPES = {
  RENT_MODAL: "RENT_MODAL",
  PAYMENT_MODAL: "PAYMENT_MODAL",
  RELEASE_MODAL: "RELEASE_MODAL",
  SEARCH_MODAL: "SEARCH_MODAL",
  TERMINATE_MODAL: "TERMINATE_MODAL",
  TRANSFER_MODAL: "TRANSFER_MODAL",
};

const DIALOG_COMPONENTS = {
  [STORAGE_DIALOG_TYPES.RENT_MODAL]: StorageRentModal,
  [STORAGE_DIALOG_TYPES.PAYMENT_MODAL]: StoragePaymentInfoModal,
  [STORAGE_DIALOG_TYPES.RELEASE_MODAL]: StorageReleaseModal,
  [STORAGE_DIALOG_TYPES.SEARCH_MODAL]: StorageSearchModal,
  [STORAGE_DIALOG_TYPES.TERMINATE_MODAL]: StorageTerminateModal,
};

const initalState = {
  showModal: () => { },
  hideModal: () => { },
  store: {},
};
export const StorageContext = createContext(initalState);
export const useStorageContext = () => useContext(StorageContext);
// This context provider is passed to any component requiring the context
const StorageProvider = ({ children }) => {
  //api helper 

  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [storageStatusList, setStorageStatusList] = useState([]);
  const [contextCategory, setContextCategory] = useState(null);
  const [contextStorage, setContextStorage] = useState(null);
  const [contextStorageOrder, setContextStorageOrder] = useState(null);
  const [contextStorages, setContextStorages] = useState([]);
  const [expiredInDays, setExpiredInDays] = useState(0);

  const [store, setStore] = useState();
  const { modalType, modalProps, modalShow } = store || {};
  const gridSize = useMemo(() => {
    return localStorage.getItem("storage-grid") ?? "xs"
  }
    , [localStorage])

  const showDialog = (modalType, modalProps = {}) => {
    setStore({
      ...store,
      modalType: modalType,
      modalProps: modalProps,
      modalShow: true,
    });
  };
  const setGridSize = (size) => {
    localStorage.setItem("storage-grid", size)
  }
  const hideDialog = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
      modalShow: false,
    });
  };

  const renderComponent = () => {
    const ModalComponent = DIALOG_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }

    return <ModalComponent
      id="global-modal"
      {...modalProps}
      modal={modalShow}

    />;
  };

  return (
    <StorageContext.Provider
      value={{
        store, showDialog, hideDialog,
        filteredCategories,
        setFilteredCategories,
        contextStorageOrder,
        setContextStorageOrder,
        filteredFeatures,
        setFilteredFeatures,
        contextCategory,
        setContextCategory,
        contextStorage,
        setContextStorage,
        contextStorages,
        setContextStorages,
        storageStatusList,
        setStorageStatusList,
        // fetchStorages,
        setGridSize,
        gridSize,
        expiredInDays, setExpiredInDays
      }}
    >
      {renderComponent()}
      {children}
    </StorageContext.Provider>
  );
};
export default StorageProvider;