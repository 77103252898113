
import axios from 'axios';
import { BoxappBackend } from './boxapp_helper'

class BoxappAppSettingBackend extends BoxappBackend {

  setAppSetting = async (param) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/setting/general",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: param
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getAppSettings = async (param) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/setting/general",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        param: param,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };


}

let _BoxappAppSettingBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappAppSettingBackend = () => {
  if (!_BoxappAppSettingBackend) {
    _BoxappAppSettingBackend = new BoxappAppSettingBackend();
  }
  return _BoxappAppSettingBackend;
};

export { getBoxappAppSettingBackend };
