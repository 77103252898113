import React from "react";
import { Button } from "reactstrap";
import {
  useGlobalModalContext,
} from "../../context/GlobalModalContext";

export const BxButton = (props) => {

  const { loading } = useGlobalModalContext();

  return (<Button
    {...props}
    loading={undefined}
    className={`${props.className} `}
    disabled={props.disabled || loading}
  >
    <i className="bx bx-loader-circle bx-spin mr-2"
      hidden={!loading && !props.loading}
    ></i>
    {props.children}</Button>);
};

