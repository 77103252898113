import React, { useState, useEffect } from "react";
import { TabContent, TabPane, NavLink, NavItem, Nav } from "reactstrap";
import { withRouter } from "react-router-dom";


//i18n
import { withTranslation } from 'react-i18next';

import { useStorageContext } from "../../../context/StorageProviderContext";

const StorageTabs = (props) => {
  const [activeTab, setActiveTab] = useState("0");
  const [tabs, setTabs] = useState([]);
  const { setContextCategory, filteredCategories } = useStorageContext();

  useEffect(() => {
    if (activeTab === "0") {
      setContextCategory();
      if (props.onSelectedCategory) {
        props.onSelectedCategory();
      }
    } else {
      if (filteredCategories && filteredCategories.length > 0) {
        setContextCategory(filteredCategories[Number(activeTab) - 1]);
        if (props.onSelectedCategory) {
          props.onSelectedCategory(filteredCategories[Number(activeTab) - 1]);
        }
      }
    }

  }, [activeTab]);


  useEffect(() => {
    if (filteredCategories.length > 0) {
      setTabs(filteredCategories);
      setActiveTab("0");
    } else {
      setTabs([]);
      setActiveTab("0");
    }
  }, [filteredCategories]);

  const NavTabs = (tabId, categoryName) => {
    return (
      <NavItem key={"nav-" + tabId}>
        <NavLink
          style={{ cursor: "pointer" }}
          className={activeTab === tabId ? "active" : ""}
          onClick={() => {
            setActiveTab(tabId);
          }}
        >
          {categoryName}
        </NavLink>
      </NavItem>
    );
  };

  const Tab = (tabId) => {
    return (
      <TabPane key={"tab-" + tabId} tabId={tabId} className="p-3">
        {props.children}
      </TabPane>
    );
  };
  return (
    <React.Fragment>
      <Nav tabs>
        {NavTabs("0", props.t('All'))}
        {tabs.map((category, i) => {
          return NavTabs((i + 1).toString(), category.name?.[props.i18n.language]);
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {Tab("0")}
        {tabs.map((_, i) => {
          return Tab((i + 1).toString());
        })}
      </TabContent>
    </React.Fragment>
  );
};


export default withRouter(withTranslation()(StorageTabs));
