import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useGlobalModalContext } from "../../context/GlobalModalContext";
import { withTranslation } from 'react-i18next';

const ActionModal = (props) => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, message, onConfirm, onCancel } = modalProps || {};

  const handleModalToggle = () => {

    hideModal();
    if (onCancel) {
      onCancel()
    }
  };

  const handleModalActionConfirm = (e) => {

    hideModal();
    if (onConfirm) {
      onConfirm(e)
    }
  }
  return (
    <SweetAlert
      title={props.t(title)}
      warning
      showCancel
      show={true}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      confirmBtnText={props.t("OK")}
      cancelBtnText={props.t("CANCEL")}
      onConfirm={handleModalActionConfirm}
      onCancel={() => handleModalToggle()}
    >
      {message}
    </SweetAlert>)
};

export default withTranslation()(ActionModal);
