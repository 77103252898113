import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../../../context/AuthProviderContext";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  FormGroup,
  Row,
  Col,
  Input,
  Button,
  Label,
  Card,
  CardBody,
  InputGroup,
  Alert,
  UncontrolledAlert,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import { Offsymbol, OnSymbol } from "../../../components/Switch/switchButton";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import "./paymentInfo.scss";
import UseAPI, {
  SUCCESS,
  API_CREATE_WALLET,
  API_CREATE_PAYMENT,
  API_UPDATE_PAYMENT,
  API_VOID_PAYMENT,
  API_GET_CUSTOMER,
} from "../../../api";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { BxButton } from "../../../components/Button";
import { optionPayment, optionPaymentStatusGroup } from "../../../util/options";
import { getBoxappOrderBackend } from "../../../helpers/boxapp_order_helper";
import {
  useGlobalModalContext,
  MODAL_TYPES,
} from "../../../context/GlobalModalContext";
import Slider from "react-rangeslider";
import { getPaymentItems } from "../../../resolver/orders";
const PaymentInfo = (props) => {
  const [remarks, setRemarks] = useState(null);
  const { showModal, loading, setLoading } = useGlobalModalContext();
  const [paymentMethodGroup, setPaymentMethodGroup] = useState(null);
  const [paymentStatusGroup, setPaymentStatusGroup] = useState(null);
  const [refNo, setRefNo] = useState("");
  const [amount, setAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [walletError, setWalletError] = useState();

  // const [amountError, setAmountError] = useState(null)
  const [paymentItemsGroupError, setPaymentItemsError] = useState(null);
  const [paymentStatusError, setpaymentStatusError] = useState(null);
  const [paymentMethodGroupError, setPaymentMethodGroupError] = useState(null);
  // const [refNoError, setRefNoError] = useState(null)
  const [paymentDateError, setPaymentDateError] = useState(null);
  const [submitButtonBlock, setSubmitButtonBlock] = useState(false);
  const [wallet, setWallet] = useState(props.customer?.wallets?.find(() => true))

  useEffect(() => {
    if (!wallet && props.customer?.id) {
      makeRequest(API_GET_CUSTOMER, props.customer?.id)
    }
  }, [wallet])

  const paidPaymentItems = useMemo(() => {
    if (!props.invoice) return;
    return getPaymentItems(props.invoice);
  }, [props.invoice, props.payment]);

  const [paymentItems, setPaymentItems] = useState(
    props.invoice?.paid_items?.filter((paid_item) => {
      const paidPaymentItem = paidPaymentItems?.find((paidPaymentItem) =>
        paidPaymentItem?.paid_item_id === paid_item?.id
      )

      if (!paidPaymentItem || props?.payment) return true

      return paidPaymentItem?.amount !== paid_item?.final_price
    })?.map((paid_item) => {

      let checked = false;
      let amount =
        props.order?.order_type === "Deposit" ? paid_item.final_price : 0;
      let distribution = [];
      if (props.payment) {
        amount = props.payment?.payment_items?.find(
          (payment_item) => payment_item?.paid_item_id === paid_item?.id
        )?.amount;
        distribution = props.payment?.payment_items?.find(
          (payment_item) => payment_item?.paid_item_id === paid_item?.id
        )?.distribution;
      } else if (paidPaymentItems) {

        const paidPaymentItem = paidPaymentItems?.find((paidPaymentItem) =>
          paidPaymentItem?.paid_item_id === paid_item?.id
        )
        distribution = paidPaymentItem?.distribution ?? []

      }

      return {
        ...paid_item,
        checked: paid_item?.item_type === "WALLET" ? true : checked,
        paid_item_id: paid_item.id,
        paid_item_price: paid_item.final_price,
        amount: paid_item?.item_type === "WALLET" ? paid_item?.final_price : amount,
        distribution,
      };
    })

  );

  const [paymentDate, setPaymentDate] = useState("");
  const [{ status, response }, makeRequest] = UseAPI();
  const { checkPermission } = useAuthContext();



  useEffect(() => {
    if (status === SUCCESS) {
      if (response.storage) {
        setSubmitButtonBlock(false);
        props.onSuccess(response.storage);
      }
      if (response.payment) {
        setSubmitButtonBlock(false);
        props.onSuccess(response.payment);
      }
      if (response.wallet) {
        setWallet(response.wallet)
      }

      if (response.customer) {
        setWallet(response.customer?.wallets?.[0] ?? null)
      }
    }
  }, [status, response]);

  const walletItemAmount = useMemo(() => {
    return Number(
      paymentItems
        ?.filter((paidItem) => paidItem?.item_type === "WALLET")
        ?.filter((paymentItem) => paymentItem.checked)
        ?.reduce((a, b) => (a = Number(a) + Number(b.amount)), 0)
    );
  }, [paymentItems]);

  const walletItemPrice = useMemo(() => {
    return Number(
      paymentItems
        ?.filter((paidItem) => paidItem?.item_type === "WALLET")
        .reduce((a, b) => (a = Number(a) + Number(b.paid_item_price)), 0)
    );
  }, [paymentItems]);
  const prepaidItem = paymentItems?.find(
    (paymentItem) => paymentItem.item_type === "PREPAID"
  );

  const showWallet = useCallback(() => {
    return wallet ? (
      <CardBody className="border mb-4">
        <Row>
          <Col sm="6">
            <div>
              <p className="text-muted mb-2">
                <i className="bx bx-wallet font-size-18 mr-2" />
                {props.t("Available Balance")}
              </p>
              <h5 className={`${wallet?.amount < 0 ? "text-danger" : ""}`}>
                ${wallet?.amount}
              </h5>
            </div>
          </Col>
          <Col sm="6">
            <div>
              <div>
                <p className="text-muted mb-2">
                  <i className="bx bx-wallet font-size-18 mr-2" />
                  {`${props.t("The amount will be to up to")}:`}
                </p>
                <h5 className="font-size-18 text-success">
                  {`$${wallet?.amount + Number(prepaidItem?.amount ?? 0)}`}
                </h5>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    ) : (
      createWallet()
    );
  }, [wallet, prepaidItem]);

  const createWallet = () => {
    return (
      <CardBody className="border mb-4">
        <Row>
          <Col xs="12" sm="6">
            <div>
              <p className="text-muted mb-2">
                {props.t("Customer does not have any wallet")}
              </p>
              <p className="text-muted mb-2">
                {props.t(
                  "If the customer pay extra or less, you can use wallet to store the fund."
                )}
              </p>
            </div>
          </Col>
          <Col xs="12" sm="6">
            <BxButton
              type="button"
              disabled={!props.customer?.id}
              onClick={async () => {
                makeRequest(API_CREATE_WALLET, props.customer?.id);
              }}
            >
              <i className="bx bx-wallet font-size-18 mr-2" />
              {props.t("Create Wallet")}
            </BxButton>
          </Col>
        </Row>
      </CardBody>
    );
  };
  useEffect(() => {
    if (!props.payment) {
      if (props.order?.order_type === "Deposit") {
        setAmount(
          Number(
            paymentItems
              ?.filter(
                (paidItem) =>
                  paidItem?.item_type !== "WALLET" &&
                  paidItem?.item_type !== "PREPAID"
              )
              .reduce((a, b) => (a = Number(a) + Number(b.amount)), 0)
          )
        );
      } else {
        const paidItemAmount = Number(
          paymentItems
            ?.filter(
              (paidItem) =>
                paidItem?.item_type !== "WALLET" &&
                paidItem?.item_type !== "PREPAID"
            )
            ?.filter((paymentItem) => paymentItem.checked)
            ?.reduce((a, b) => (a = Number(a) + Number(b.amount)), 0) ?? 0
        );
        const prepaidItemAmount = Number(
          paymentItems
            ?.filter((paidItem) => paidItem?.item_type === "PREPAID")
            ?.filter((paymentItem) => paymentItem.checked)
            ?.reduce((a, b) => (a = Number(a) + Number(b.amount)), 0) ?? 0
        );
        setAmount(
          paidItemAmount + prepaidItemAmount //+ walletItemAmount
        );
      }
      const paidItemPrice = Number(
        paymentItems
          ?.filter(
            (paidItem) =>
              paidItem?.item_type !== "WALLET" &&
              paidItem?.item_type !== "PREPAID"
          )
          .reduce((a, b) => (a = Number(a) + Number(b.paid_item_price)), 0)
      );

      setPrice(paidItemPrice);
    }
  }, [paymentItems]);

  useEffect(() => {
    if (!props.payment) {
      return;
    }
    setRemarks(props.payment?.remarks);
    setAmount(Number(props.payment?.amount));
    setPrice(Number(props.payment?.billing_amount));
    setRefNo(paymentMethodGroup?.value !== "CASH" && props.payment?.ref_no);

    if (props.payment?.payment_date) {
      setPaymentDate(new Date(props.payment?.payment_date * 1000));
    }
    if (props.payment?.status) {
      const op = optionPaymentStatusGroup[0].options.find((option) => {
        return option.value === props.payment?.status;
      });
      setPaymentStatusGroup(op);
    }
    if (props.payment?.payment_method) {
      const op = optionPayment[0].options.find((option) => {
        return option.value === props.payment?.payment_method;
      });
      setPaymentMethodGroup(op);
    }
  }, [props.payment]);

  const onSubmit = async () => {
    if (!paymentMethodGroup) {
      setPaymentMethodGroupError(
        props.t("Payment Method") + " " + props.t("is required")
      );

      return;
    } else {
      setPaymentMethodGroupError(null);
    }
    if (props.mode === "edit") {
      try {
        const req = {
          ref_no: paymentMethodGroup?.value === "CASH" ? undefined : refNo,
          remarks: remarks,
          payment_method: paymentMethodGroup?.value,
        };
        makeRequest(
          API_UPDATE_PAYMENT,
          props.order?.branch_id,
          props.order?.id,
          props.invoice?.id,
          props.payment?.id,
          req
        );
      } catch (err) {
      } finally {
        setSubmitButtonBlock(false);
      }
      return;
    }
    //wallet
    const walletItem = props.invoice?.paid_items?.find(
      (paidItem) => paidItem.item_type === "WALLET"
    );
    if (walletItem) {
      if (!wallet) {
        setWalletError("wallet is required");
        return
      }
      const totalWalletAmt = paymentItems
        ?.filter(
          (paymentItem) =>
            paymentItem.item_type !== "WALLET" &&
            paymentItem?.item_type !== "PREPAID"
        )
        ?.reduce((a, b) => (a = Number(a) + Number(b.walletAmt ?? 0)), 0);

      if (walletItem?.final_price - totalWalletAmt !== 0) {
        setWalletError("invalid wallet item amount");
        return;
      } else {
        setWalletError();
      }
      const uncheckedItem = paymentItems?.find(
        (paymentItem) =>
          paymentItem.item_type !== "WALLET" &&
          paymentItem?.item_type !== "PREPAID" &&
          paymentItem?.walletAmt > 0 &&
          !paymentItem?.checked
      );

      if (uncheckedItem) {
        setPaymentItemsError(
          props.t("paid items must be checked if wallet is used")
        );
        return;
      } else {
        setPaymentItemsError();
      }
    }
    //prepaid
    const prepaidItem = props.invoice?.paid_items?.find(
      (paidItem) => paidItem.item_type === "PREPAID"
    );
    if (prepaidItem) {
      if (!wallet) {
        setWalletError("wallet is required");
        return
      }
    }
    if (!paymentDate) {
      setPaymentItemsError(props.t("Paid At") + " " + props.t("is required"));
      return;
    }
    const invalidPaymentItem = paymentItems?.filter((paymentItem) => {
      if (paymentItem?.item_type !== "PREPAID") {
        if (
          paymentItem?.final_price <
          paymentItem?.amount + (paymentItem?.walletAmt ?? 0)
        ) {
          setPaymentItemsError(
            props.t("amount cannot be more than the the price")
          );
          return paymentItem;
        }
      }
    });

    if (invalidPaymentItem?.length > 0) {
      return;
    }

    setPaymentDateError(null);
    setSubmitButtonBlock(true);
    setTimeout(() => {
      setSubmitButtonBlock(false);
    }, 3000);
    const req = {
      invoice_type: props.invoice.invoice_type,
      order_type: props.order?.order_type,
      billing_amount: paymentItems?.reduce(
        (a, b) => (a = Number(a) + Number(b.paid_item_price)),
        0
      ),
      ref_no: refNo,
      amount: Number(amount),
      remarks: remarks,
      payment_method: paymentMethodGroup.value,
      payment_date: moment(paymentDate).unix(),
    };
    if (props.order?.order_type === "Deposit") {
      req.paid_items = paymentItems?.map((paid_item) => {
        return {
          paid_item_id: paid_item?.paid_item_id,
          paid_item_price: paid_item?.final_price,
          amount: paid_item?.final_price,
        };
      });
    } else {
      req.paid_items = paymentItems
        ?.filter((paid_item) => paid_item?.checked)
        ?.map((paid_item) => {
          if (paid_item?.item_type === "WALLET") {
            return {
              item_id: paid_item?.item_id,
              item_type: paid_item?.item_type,
              paid_item_id: paid_item?.paid_item_id,
              paid_item_price: paid_item?.final_price,
              amount: paid_item?.amount,
              distribution: paymentItems
                ?.filter(
                  (paid_item) =>
                    paid_item?.checked &&
                    paid_item?.item_type !== "WALLET" &&
                    paid_item?.item_type !== "PREPAID"
                )
                ?.map((paid_item) => {
                  return {
                    paid_item_id: paid_item?.id,
                    item_id: paid_item?.item_id,
                    item_type: paid_item?.item_type,
                    walletAmt: paid_item?.walletAmt,
                  };
                }),
            };
          } else if (paid_item?.item_type === "PREPAID") {
            return {
              item_id: paid_item?.item_id,
              item_type: paid_item?.item_type,
              paid_item_id: undefined,
              paid_item_price: paid_item?.paid_item_price,
              amount: paid_item?.amount,
            };
          } else {
            return {
              item_id: paid_item?.item_id,
              item_type: paid_item?.item_type,
              paid_item_id: paid_item?.paid_item_id,
              paid_item_price: paid_item?.final_price,
              amount: paid_item?.amount + (paid_item?.walletAmt ?? 0),
            };
          }
        });
    }

    if (
      req.paid_items?.filter(
        (paid_item) =>
          paid_item?.item_type !== "WALLET" &&
          paid_item?.item_type !== "PREPAID"
      ).length === 0
    ) {
      setPaymentItemsError(props.t("no paid items"));
      return;
    } else {
      setPaymentItemsError(null);
    }

    try {
      makeRequest(
        API_CREATE_PAYMENT,
        props.order?.branch_id,
        props.order?.id,
        props.invoice?.id,
        req
      );
    } catch (err) {
    } finally {
      setSubmitButtonBlock(false);
    }
  };

  const walletItemList = (!paymentItems?.filter((paymentItem) => paymentItem.item_type === "WALLET")?.every((paymentItem) => paymentItem?.distribution?.length > 0) || props?.payment) && (
    <Row className="border-top border-5 pt-4 md-4">
      <div className="form-check  ml-4"></div>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Paid Items")}
        </Label>
      </Col>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Items Price")} $
        </Label>
      </Col>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Paid")} $
        </Label>
      </Col>
      <Col>
        <Label className="font-12" htmlFor="">
          {props.t("Pay For")}
        </Label>
        <span className="ml-4 text-danger">
          {!!walletError && props.t(walletError)}
        </span>
      </Col>

      <Col xs="12">
        {paymentItems
          ?.filter((paymentItem) => paymentItem.item_type === "WALLET")
          ?.map((item, i) => {
            const paidPaymentItem = paidPaymentItems?.find(
              (paidPaymentItem) =>
                paidPaymentItem?.paid_item?.item_id === item?.item_id
            );
            if (paidPaymentItem?.distribution?.length > 0 && !props.payment) {
              return null
            }
            return (
              <Row className=" mb-1" key={i}>
                <div className="form-check mb-3 ml-4">
                  <input
                    disabled={true}
                    className="form-check-input font-size-14 largerCheckbox"
                    type="checkbox"
                    name={`checkbox-${i}`}
                    checked={item?.checked}
                  />
                </div>

                <Col xs="2">{`${props.t("Wallet Paid")}`}</Col>

                <Col xs="2">
                  <div className="input-group">
                    <Input
                      className={"bg-light text-gray bg-opacity-10"}
                      type="number"
                      maxLength="8"
                      name={"itemPrice-" + i}
                      disabled={true}
                      value={item?.paid_item_price}
                    />
                  </div>
                </Col>

                <Col xs="2">
                  <div className="input-group">
                    <Input
                      className={"bg-light text-gray bg-opacity-10"}
                      type="number"
                      maxLength="8"
                      name={"itemPaid-" + i}
                      disabled={true}
                      value={item?.paid_item_price}
                    />
                  </div>
                </Col>
                <Col>
                  <Row className="mb-2">
                    <Col xs="4">
                      <Label className="font-12" htmlFor="">
                        {props.t("Paid Items")}
                      </Label>
                    </Col>
                    <Col>
                      <Label className="font-12" htmlFor="">
                        {props.t("Pay Amount")} $
                      </Label>
                    </Col>
                  </Row>

                  {paymentItems
                    ?.filter(
                      (paymentItem) =>
                        paymentItem.item_type !== "WALLET" &&
                        paymentItem?.item_type !== "PREPAID"
                    )
                    ?.map((subItem, i) => {
                      return (
                        <Row key={i}>
                          {subItem?.item_type === "STORAGE" ? (
                            <Col xs="4">
                              {`${props.t(subItem?.item_type)} - ${subItem?.item?.code
                                }`}
                            </Col>
                          ) : subItem?.item_type === "REPAY" ? (
                            <Col xs="4">{`${props.t("Repay")}`}</Col>
                          ) : (
                            <Col xs="4">
                              {subItem?.item?.charge_name?.[
                                props.i18n.language
                              ] ?? ""}
                            </Col>
                          )}
                          <Col>
                            <Row className="mb-2">
                              <Col xs={2} className="float-start">
                                <span>
                                  {props.payment
                                    ? item?.distribution?.find(
                                      (distributionItem) =>
                                        distributionItem?.paid_item_id ===
                                        subItem?.paid_item_id
                                    )?.walletAmt
                                    : subItem?.walletAmt ?? 0}
                                </span>
                              </Col>

                              {!props.payment && paymentItems?.find((paymentItem) => {
                                return paymentItem?.item_id === subItem?.item_id
                              })?.checked && (
                                  <Col>
                                    <Slider
                                      value={Number(subItem?.walletAmt ?? 0)}
                                      name={"payAmt-" + i}
                                      min={0}
                                      max={Number(item?.paid_item_price)}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        const p = paymentItems?.map(
                                          (paymentItem) => {
                                            if (
                                              paymentItem?.paid_item_id ===
                                              subItem?.paid_item_id
                                            ) {
                                              const walletAmt =
                                                Number(value) >= 0
                                                  ? Number(value)
                                                  : 0;
                                              return {
                                                ...paymentItem,
                                                walletAmt: walletAmt,
                                              };
                                            }
                                            return paymentItem;
                                          }
                                        );
                                        setWalletError();
                                        setPaymentItems(p);
                                      }}
                                    />
                                  </Col>
                                )}
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            );
          })}
      </Col>
    </Row>
  );

  const prepaidItemList = (
    <Row className="border-top border-5 pt-4 md-4" >
      <div className="form-check  ml-4"></div>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Customer paid extra")}
        </Label>
      </Col>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Extra Amount")} $
        </Label>
      </Col>

      <Col xs="12">
        <Row className=" mb-1">
          <div className="form-check mb-3 ml-4">
            <input
              hidden={props.payment}
              disabled={wallet ? false : true}
              className={wallet ? "form-check-input font-size-14 largerCheckbox" : "bg-light text-gray bg-opacity-10"}
              type="checkbox"
              name={`checkbox-prepaid`}
              checked={prepaidItem?.checked ?? false}
              onChange={(e) => {
                if (prepaidItem) {
                  setPaymentItems(
                    paymentItems?.map((paymentItem) => {
                      if (paymentItem?.item_type === "PREPAID") {
                        return {
                          ...paymentItem,
                          checked: e.target.checked,
                        };
                      }
                      return paymentItem;
                    })
                  );
                } else {
                  setPaymentItems([
                    ...paymentItems,
                    {
                      item_id: wallet?.id,
                      item_type: "PREPAID",
                      paid_item_price: 0,
                      checked: e.target.checked,
                      amount: 0,
                    },
                  ]);
                }
              }}
            />
          </div>

          <Col xs="2">{`${props.t("Extra Amount")}`}</Col>

          <Col xs="2">
            <div className="input-group">
              <Input
                className={
                  !prepaidItem?.checked
                    ? "bg-light text-gray bg-opacity-10"
                    : ""
                }
                type="number"
                maxLength="8"
                name={"payAmt-prepaid"}
                disabled={!prepaidItem?.checked}
                value={prepaidItem?.amount ?? 0}
                onChange={(e) => {
                  const p = paymentItems?.map((paymentItem) => {
                    if (paymentItem?.item_type === "PREPAID") {
                      return {
                        ...paymentItem,
                        paid_item_price:
                          Number(e.target.value) >= 0
                            ? Number(e.target.value)
                            : 0,
                        amount:
                          Number(e.target.value) >= 0
                            ? Number(e.target.value)
                            : 0,
                      };
                    }
                    return paymentItem;
                  });
                  setPaymentItems(p);
                }}
              />
            </div>
          </Col>
          {<Col>{showWallet()}</Col>}
        </Row>
      </Col>
    </Row>
  );

  const UnPaidPaymentItemList = (
    <Row className="border-top border-5 pt-4 md-4">
      <div className="form-check  ml-4"></div>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Paid Items")}
        </Label>
      </Col>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Items Price")} $
        </Label>
      </Col>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Paid")} $
        </Label>
      </Col>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Need to pay")} $
        </Label>
      </Col>
      <Col xs="2">
        <Label className="font-12" htmlFor="">
          {props.t("Pay Amount")} $
        </Label>
      </Col>
      <div className="pl-4">
        <Label className="font-12" htmlFor="">
          {props.t("Status")}
        </Label>
      </div>
      <Col xs="12">
        {paymentItems
          ?.filter(
            (paymentItem) =>
              paymentItem.item_type !== "WALLET" &&
              paymentItem?.item_type !== "PREPAID"
          )
          ?.map((item, i) => {
            const paidPaymentItem = paidPaymentItems?.find(
              (paidPaymentItem) =>
                paidPaymentItem?.paid_item?.item_id === item?.item_id
            );

            if (paidPaymentItem?.amount === item?.final_price) {
              return null
            }
            const paidPrice =
              (paidPaymentItem?.amount ?? 0) + Number(item?.walletAmt ?? 0);
            let status = props.t("N/A");
            if (!paidPaymentItem?.amount || paidPaymentItem?.amount === 0) {
              status = props.t("PENDING");
            } else if (item?.paid_item_price ?? 0 > paidPaymentItem?.amount) {
              status = props.t("PARTIAL COMPLETED");
            } else if (paidPaymentItem?.amount ?? 0 >= item?.paid_item_price) {
              status = props.t("COMPLETED");
            }
            if (paidPaymentItem && item?.paid_item_price === paidPrice) {
              return null
            }

            return (
              <Row className=" mb-1" key={i}>
                <div className="form-check mb-3 ml-4">
                  <input
                    disabled={
                      props.order?.order_type === "Deposit" ||

                      props.payment
                    }
                    className="form-check-input font-size-14 largerCheckbox"
                    type="checkbox"
                    name={`checkbox-${i}`}
                    checked={
                      props.order?.order_type === "Deposit" ||

                        props.payment
                        ? true
                        : item.checked ?? false
                    }
                    onChange={(e) => {
                      setPaymentItems(
                        paymentItems?.map((paymentItem) => {
                          if (
                            paymentItem?.paid_item_id === item?.paid_item_id
                          ) {
                            return {
                              ...paymentItem,
                              checked: e.target.checked,
                            };
                          }
                          return paymentItem;
                        })
                      );
                    }}
                  />
                </div>

                {item?.item_type === "STORAGE" ? (
                  <Col xs="2">
                    {`${props.t(item?.item_type)} - ${item?.item?.code}`}
                  </Col>
                ) : (
                  <Col xs="2">
                    {item?.item?.charge_name?.[props.i18n.language] ?? ""}
                  </Col>
                )}

                <Col xs="2">
                  <div className="input-group">
                    <Input
                      className={"bg-light text-gray bg-opacity-10"}
                      type="number"
                      maxLength="8"
                      name={"itemPrice-" + i}
                      disabled={true}
                      value={item?.paid_item_price ?? 0
                      }
                    />
                  </div>
                </Col>

                <Col xs="2">
                  <div className="input-group">
                    <Input
                      className={"bg-light text-gray bg-opacity-10"}
                      type="number"
                      maxLength="8"
                      name={"itemPaid-" + i}
                      disabled={true}
                      value={paidPrice}
                    />
                  </div>
                </Col>
                <Col xs="2">
                  <div className="input-group">
                    <Input
                      className={"bg-light text-gray bg-opacity-10"}
                      type="number"
                      maxLength="8"
                      name={"remains-" + i}
                      disabled={true}
                      value={
                        item?.paid_item_price - paidPrice
                      }
                    />

                    <span className=" input-group-text">
                      <i
                        className="bx bxs-send align-middle mr-1"
                        disabled={
                          !item?.checked ||
                          item?.paid_item_price - paidPrice === 0
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const p = paymentItems?.map((paymentItem) => {
                            if (
                              paymentItem?.paid_item_id === item?.paid_item_id
                            ) {
                              return {
                                ...paymentItem,
                                amount: item?.paid_item_price - paidPrice,
                              };
                            }
                            return paymentItem;
                          });
                          setPaymentItems(p);
                        }}
                      ></i>
                    </span>
                  </div>
                </Col>
                <Col xs="2">
                  <div className="input-group">
                    <Input
                      disabled={
                        !item?.checked ||
                        props.order?.order_type === "Deposit" ||

                        props.payment
                      }
                      className={
                        !item?.checked ||
                          props.order?.order_type === "Deposit" ||

                          props.payment
                          ? "bg-light text-gray bg-opacity-10"
                          : ""
                      }
                      type="number"
                      maxLength="8"
                      max={item?.paid_item_price - paidPrice}
                      name={"payAmt-" + i}
                      value={
                        item?.item_type === "WALLET"
                          ? 0
                          : Number(item?.amount) ?? 0
                      }
                      onChange={(e) => {
                        const p = paymentItems?.map((paymentItem) => {
                          if (
                            paymentItem?.paid_item_id === item?.paid_item_id
                          ) {
                            return {
                              ...paymentItem,
                              amount:
                                Number(e.target.value) >= 0
                                  ? Number(e.target.value)
                                  : 0,
                            };
                          }
                          return paymentItem;
                        });
                        setPaymentItems(p);
                      }}
                    />
                  </div>
                </Col>
                <div className="">
                  <span
                    className={` pl-2 pr-2 font-size-12 ml-2 badge badge-pill ${status === props.t("COMPLETED")
                      ? "badge-success"
                      : status === props.t("CANCELLED")
                        ? "badge-danger"
                        : status === props.t("PENDING")
                          ? "badge-warning"
                          : status === props.t("PARTIAL_COMPLETED")
                            ? "badge-danger"
                            : "badge-secondary"
                      }`}
                  >
                    {props.t(status)}
                  </span>
                </div>
              </Row>
            );
          })}
      </Col>
    </Row>
  );

  const PaidPaymentItemList = useMemo(() => {
    return (
      <Row>
        <div className="form-check  ml-4"></div>
        <Col xs="2">
          <Label className="font-12" htmlFor="">
            {props.t("Paid Items")}
          </Label>
        </Col>
        <Col xs="2">
          <Label className="font-12" htmlFor="">
            {props.t("Pay Amount")}
          </Label>
        </Col>
        <Col xs="12">
          {paymentItems
            ?.filter(
              (paymentItem) =>
                paymentItem.item_type !== "WALLET" &&
                paymentItem?.item_type !== "PREPAID"
            )
            ?.map((item, i) => {
              return (
                <Row className=" mb-1" key={i}>
                  <div className="form-check mb-3 ml-4"></div>
                  {item?.item_type === "STORAGE" ? (
                    <Col xs="2">
                      {`${props.t(item?.item_type)} - ${item?.item?.code}`}
                    </Col>
                  ) : item?.item_type === "WALLET" ? (
                    <Col xs="2">{`${props.t("Wallet Paid")}`}</Col>
                  ) : item?.item_type === "REPAY" ? (
                    <Col xs="2">{`${props.t("Repay")}`}</Col>
                  ) : (
                    <Col xs="2">
                      {item?.item?.charge_name?.[props.i18n.language] ?? ""}
                    </Col>
                  )}
                  <Col xs="2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <Input
                        disabled={true}
                        className={"bg-light text-gray bg-opacity-10"}
                        type="number"
                        maxLength="8"
                        name={"payAmt-" + i}
                        value={Number(item?.amount ?? 0)}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Row>
    );
  }, [props.payment, paymentItems]);

  return (
    <AvForm action="#" onValidSubmit={onSubmit}>
      <Card>
        <CardBody>
          <Row className="mb4">
            <Col>
              <Label>{props.t("Payment No")}:</Label>
              <span className="text-primary ml-2">
                {props.payment?.payment_no ?? ""}
              </span>
              <span
                className={`font-size-13 ml-2 badge badge-pill ${props.order?.order_type === "Deposit"
                  ? "badge-primary"
                  : "badge-success"
                  }`}
              >
                {props.t(props.order?.order_type)}
              </span>
              <span
                hidden={props.invoice?.invoice_type !== "Refund"}
                className={`font-size-13 ml-2 badge badge-pill badge-warning`}
              >
                {props.t(props.invoice?.invoice_type)}
              </span>
            </Col>
            <Col>
              <div className="text-sm-right">
                {props.t("created_at")}{" "}
                {moment
                  .unix(props.invoice?.created_at)
                  .format("YYYY-MM-DD HH:mm:ss")}
                {props.payment && props.payment?.status === "PAID" && (
                  <BxButton
                    type="button"
                    color="danger"
                    className="ml-4"
                    onClick={() => {
                      showModal(MODAL_TYPES.ACTION_MODAL, {
                        title: props.t("Are you Confirm?"),
                        message: props.t(`Please confirm to void the payment`),
                        onConfirm: () => {
                          makeRequest(
                            API_VOID_PAYMENT,
                            props.order?.branch_id,
                            props.order?.id,
                            props.invoice?.id,
                            props.payment?.id
                          );
                        },
                        onCancel: () => { },
                      });
                    }}
                  >
                    {props.t("Void Payment")}
                  </BxButton>
                )}
              </div>
            </Col>
          </Row>

          {props.payment && (
            <FormGroup>
              <Label>{props.t("Payment Status")}: </Label>
              <Select
                required
                value={paymentStatusGroup}
                isDisabled={true}
                onChange={(e) => setPaymentStatusGroup(e)}
                options={optionPaymentStatusGroup}
                classNamePrefix="select-selection"
              />
              <div className={"error"}>
                {paymentStatusError ? paymentStatusError : ""}
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <Label>{props.t("Paid At")}:</Label>
            <InputGroup>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                className={
                  props.payment
                    ? "bg-light text-gray bg-opacity-10 form-control"
                    : "form-control"
                }
                readOnly={props.payment}
                required
                selected={paymentDate}
                onChange={(date) => setPaymentDate(date)}
              />
            </InputGroup>
            <div className={"error"}>
              {paymentDateError ? paymentDateError : ""}
            </div>
          </FormGroup>
          <FormGroup>
            <Label> {props.t("Payment Method")}: </Label>
            <Select
              required
              isDisabled={
                props.payment?.status === "VOID" ||
                props.payment?.status === "COMPLETED" ||
                props.payment?.status === "CANCELLED"
              }
              placeholder={props.t("Select...")}
              value={paymentMethodGroup}
              onChange={(e) => setPaymentMethodGroup(e)}
              options={optionPayment}
              classNamePrefix="select-selection"
            />
            <div className={"error"}>
              {paymentMethodGroupError ? paymentMethodGroupError : ""}
            </div>
          </FormGroup>

          {walletItemList}

          {paymentItems?.filter(
            (paymentItem) =>
              paymentItem.item_type !== "WALLET" &&
              paymentItem.item_type !== "PREPAID"
          )?.length > 0 &&
            (props.payment?.status === "PAID" || props.payment?.status === "VOID"
              ? PaidPaymentItemList
              : UnPaidPaymentItemList)}




          {props.order?.order_type !== "Deposit" && prepaidItemList}

          <div className={"error"}>
            {paymentItemsGroupError ? paymentItemsGroupError : ""}
          </div>
          {!props.payment && props.order?.order_type !== "Deposit" && (
            <FormGroup className="border-top border-5 pt-4 md-4">
              <Label>{props.t("Billing Amount")}</Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <Input
                  className={"bg-light text-gray bg-opacity-10"}
                  disabled={true}
                  value={price}
                  name="price"
                  type="number"
                />
              </div>
            </FormGroup>
          )}
          {paymentItems?.find(
            (paidItem) => {
              return paidItem.item_type === "WALLET" && paidItem?.checked === true;
            }
          ) && (
              <FormGroup className="mb-4">
                <Label htmlFor="validationCustomName text-danger">
                  {props.t("Pay From Wallet")}
                </Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-danger">$</span>
                  </div>
                  <Input
                    className={"text-danger bg-light text-gray bg-opacity-10"}
                    disabled={true}
                    value={walletItemPrice}
                    name="walletItemPrice"
                    type="number"
                  />
                </div>
              </FormGroup>
            )}

          <FormGroup className="mb-4">
            <Label htmlFor="validationCustomName">
              {props.t("Pay Amount")}

            </Label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <Input
                className={"bg-light text-gray bg-opacity-10"}
                disabled={true}
                value={amount}
                name="amount"
                type="number"
              // onChange={(e) => {
              //   setAmount(e.target.value);
              // }}
              />
            </div>
          </FormGroup>

          {paymentMethodGroup?.value !== "CASH" && (
            <FormGroup className="mb-4">
              <Label>{props.t("Reference No")}:</Label>
              <Input
                disabled={props.payment?.status === "VOID"}
                className={`${props.payment?.status === "VOID" ? "bg-light text-gray bg-opacity-10" : ""}`}
                value={refNo}
                name="refNo"
                type="text"
                onChange={(e) => {
                  setRefNo(e.target.value);
                }}
              />
            </FormGroup>
          )}

          <FormGroup className="border-top border-5 pt-4 md-4">
            <Label>
              {props.t("Invoice")}
              {props.t("Remarks")}
            </Label>
            <Input
              type="textarea"
              maxLength="500"
              name="remarks"
              onChange={(e) => setRemarks(e.target.value)}
              value={remarks ?? ""}
            />
          </FormGroup>

          <Row>
            <Col>
              <BxButton
                loading={submitButtonBlock}
                disabled={submitButtonBlock}
                type="submit"
                color={props.onClose ? "success" : "primary"}
              >
                {props.t("Submit")}
              </BxButton>
              {props.onClose && (
                <Button
                  type="button"
                  color="secondary"
                  onClick={props.onClose ? props.onClose : props.onClose}
                >
                  {props.t("Close")}
                </Button>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </AvForm>
  );
};
export default withTranslation()(PaymentInfo);
