import React, {
  lazy,
  Suspense,
  Component,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { BxButton } from "../../Button";
import moment from "moment-timezone";
import { withTranslation } from "react-i18next";

import DatePicker from "react-datepicker";
// Redux

import "./storageRent.scss";
import UseAPI, {
  SUCCESS,
  API_RENT_EXTEND,
  API_RENT_STORAGE,
  API_CREATE_CONTRACT,
  API_CREATE_DEPOSIT,
  API_EXTEND_CONTRACT,
} from "../../../api";
import { useStorageContext } from "../../../context/StorageProviderContext";
import {
  useCustomerContext,
  CUSTOMER_DIALOG_TYPES,
} from "../../../context/CustomerProviderContext";
import { useBranchContext } from "../../../context/BranchProviderContext";
import {
  useGlobalModalContext,
  MODAL_TYPES,
} from "../../../context/GlobalModalContext";
import { getBoxappContractBackend } from "../../../helpers/boxapp_contract_helper";
import { getBoxappOrderBackend } from "../../../helpers/boxapp_order_helper";

const ContractPanel = lazy(() => import("./StorageRentContractPanel"));
const OrderPanel = lazy(() => import("./StorageRentOrderPanel"));
const DepositPanel = lazy(() => import("./StorageRentDepositPanel"));

const StorageRent = (props) => {
  const [expand, setExpand] = useState(0);

  const [process, setProcess] = useState([]);

  const { contextStorage } = useStorageContext();
  //contract
  const { contextBranch } = useBranchContext();
  const [{ status, response }, makeRequest] = UseAPI();

  const [submitButtonBlock, setSubmitButtonBlock] = useState(false);
  const [orderRequestBody, setOrderRequestBody] = useState();
  const [depositRequestBody, setDepositRequestBody] = useState();
  const [contractRequestBody, setContractRequestBody] = useState();
  const { showModal } = useGlobalModalContext();

  const title = useMemo(() => {
    if (props.mode === "nextRentalOrder") {
      return props.t("Next Rental Order");
    }
    props.t("Rent Storage");
  }, [props.mode]);

  const setRequestBody = useCallback(async (e, i) => {

    switch (i) {
      case "ContractPanel":
        setContractRequestBody(e);
        break;
      case "OrderPanel":
        setOrderRequestBody(e);
        break;
      case "DepositPanel":
        setDepositRequestBody(e);
        break;
      default:
        return;
    }
  }, []);

  const steps = useMemo(() => {
    switch (props.mode) {
      case "extend": {
        return ["ContractPanel"];
      }
      case "rent": {
        return ["ContractPanel", "OrderPanel", "DepositPanel"];
      }
      case "nextRentalOrder": {
        return ["OrderPanel"];
      }
      default:
        return [];
    }
  }, [props.mode]);

  const isFinished = useCallback((i) => process?.indexOf(i) > -1, [process]);
  const formValid = useMemo(() => {
    if (steps.length === 1) {
      return true;
    }
    return process.length >= steps.length;
  }, [process]);

  useEffect(() => {
    if (status === SUCCESS) {
      if (response.storage) {
        props.onSuccess(props.mode, response.storage);
      }
      if (response.contract) {
        props.onSuccess(props.mode, response.contract);
      }
    }
  }, [status, response]);

  const togglemodal = () => {
    props.onClose();
  };

  const submitRent = async (request = null) => {
    try {
      
        setSubmitButtonBlock(true);
      const interval = props.interval ? props.interval : 2000
    const timeoutId = setTimeout(() => {
        setSubmitButtonBlock(false);
        clearTimeout(timeoutId)
    }, interval)
      if (props.mode === "nextRentalOrder") {
        makeRequest(API_RENT_STORAGE, contextBranch?.id, contextStorage?.id, {
          ...request,
          ...orderRequestBody,
          contract_id: props.order?.contract_id,
          customer_id: props.order?.customer.id,
        });
      } else if (props.mode === "extend") {
        makeRequest(API_EXTEND_CONTRACT, contextBranch?.id, {
          ...request,
          ...contractRequestBody,
          master_contract_id: props.masterContractID,
          storage_id: contextStorage?.id,
        });
      } else {
        contractRequestBody.storage_id = contextStorage.id;
        const { contract } = await getBoxappContractBackend().create(
          contextBranch?.id,
          contractRequestBody
        );
        if (contract) {
          if (props.mode === "rent") {
            if (depositRequestBody?.deposit_require) {
              const { deposit_order } =
                await getBoxappOrderBackend().createDepositOrder(
                  contextBranch?.id,
                  {
                    ...depositRequestBody,
                    customer_id: contractRequestBody.customer_id,
                    start_date: contractRequestBody.start_date,
                    end_date: contractRequestBody.end_date,
                    rent_type: contractRequestBody.rent_type,
                    rent_period: contractRequestBody.rent_period,
                    storage_id: contextStorage?.id,
                    branch_id: contextBranch?.id,
                    master_contract_id: contract.master_contract_id,
                  }
                );
              if (deposit_order) {
                makeRequest(
                  API_RENT_STORAGE,
                  contextBranch?.id,
                  contextStorage?.id,
                  {
                    ...orderRequestBody,
                    contract_id: contract.id,
                    customer_id: contractRequestBody.customer_id,
                  }
                );
              }
            } else {
              makeRequest(
                API_RENT_STORAGE,
                contextBranch?.id,
                contextStorage?.id,
                {
                  ...orderRequestBody,
                  contract_id: contract.id,
                  customer_id: contractRequestBody.customer_id,
                }
              );
            }
          } else if (props.mode === "extend") {
            makeRequest(
              API_RENT_EXTEND,
              contextBranch?.id,
              contextStorage?.id,
              {
                ...orderRequestBody,
                contract_id: contract.id,
                customer_id: contractRequestBody.customer_id,
                reference_order_id: props.order.id,
              }
            );
          }
        }
      }
    } catch (err) {
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: props.t("Rent failed"),
        messageType: "error",
        message: err,
      });
    }
  };

  const startDate = useMemo(() => {
    if (props.mode === "extend" && props?.order?.contract?.end_date) {
      return new Date(
        moment.unix(props.order?.contract?.end_date).add(1, "days").unix() *
        1000
      );
    } else if (
      props.mode === "nextRentalOrder" &&
      props.order?.actual_end_date
    ) {
      return new Date(
        moment.unix(props.order?.actual_end_date).add(1, "days").unix() * 1000
      );
    }
  }, [props.mode]);

  const panel = (step, i) => {
    let Companent = null;
    switch (step) {
      case "ContractPanel":
        Companent = ContractPanel;
        break;
      case "OrderPanel":
        Companent = OrderPanel;
        break;
      case "DepositPanel":
        Companent = DepositPanel;
        break;
      default:
        return;
    }
    const orderItems = props.order?.invoices
      ?.filter(
        (invoice) =>
          invoice.invoice_type === "Payment" && invoice?.status !== "CANCELLED"
      )
      ?.flatMap((invoice) => invoice.paid_items)
      .filter((item) => item.item_type !== "CONTRACT_VALUE" && item.item_type !== "PREPAID" && item.item_type !== "WALLET");

    let paidItems = [];
    if (orderItems) {
      const storage = orderItems.find((item) => item.item_type === "STORAGE");
      const items = [
        storage,
        ...orderItems.filter((item) => item.item_type !== "STORAGE"),
      ];

      paidItems = items?.map((item, i) => {
        const chargeType = contextBranch?.charge_types?.find(
          (chargeType) => chargeType.id === item?.item_id
        );

        return {
          index: i,
          lastOrderPaidItem: item,
          item_id: item?.item_id,
          charge_mode: chargeType?.charge_mode,
          quantize: chargeType?.quantize,
          unit_price: chargeType?.unit_price,
          unit: chargeType?.unit,
          start_date: item?.end_date
            ? moment.unix(item?.end_date).add(1, "days").toDate()
            : undefined,
          quantity_start: item?.quantity_end ?? 0,
          charge_name: item?.item?.charge_name,
          item: item?.item_type === "STORAGE" ? contextStorage ?? null : null,
          rent_period:
            item?.item_type === "STORAGE"
              ? props.order?.rent_period
              : undefined,
          rent_type:
            item?.item_type === "STORAGE" ? item?.item?.rent_type : undefined,
          item_type: item?.item_type,
          original_price: item?.original_price ?? 0,
        };
      });
    } else {
      paidItems = [
        {
          index: 0,
          item: contextStorage ?? null,
          item_type: "STORAGE",
          original_price: 0,
        },
      ];
    }
    return (
      <Companent
        key={i}
        totalSteps={steps.length}
        step={i + 1}
        onSubmit={(e) => {
          if (steps.length === 1) {
            submitRent(e);
          } else {
            setRequestBody(e, step);
            setProcess([...process, step]);
            setExpand(i + 1);
          }
        }}
        customer={contractRequestBody ? contractRequestBody.customer : props.customer}
        branch={contextBranch}
        storage={contextStorage}
        mode={props.mode}
        order={props.order}
        isFinished={isFinished(step)}
        onCancel={() => {
          setRequestBody(null, step);
          setExpand((prevState) => (prevState === i ? -1 : i));
          setProcess(process.filter((p) => p !== step));
        }}
        open={expand === i}
        startDate={startDate ?? new Date()}
        orderDate={new Date()}
        paidItems={paidItems}
      ></Companent>
    );
  };
  return !contextStorage ? null : (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        tabIndex="-1"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>{title}</ModalHeader>
          <div className="ml-3">
            <h5 className="mt-3 font-13">
              {" "}
              {props.t("Rental Location Number")} - {contextStorage?.code}
            </h5>
          </div>
          <ModalBody>
            <React.Suspense fallback={`${props.t("Loading")}.....`}>
              {steps.map((e, i) => {
                return panel(e, i);
              })}
            </React.Suspense>
            {steps.length > 1 && (
              <BxButton
                loading={submitButtonBlock}
                disabled={!formValid || submitButtonBlock}
                type="button"
                color="success"
                onClick={() => submitRent(orderRequestBody)}
              >
                {props.t("Submit")}
              </BxButton>
            )}
            {steps.length > 1 && (
              <Button type="button" color="secondary" onClick={togglemodal}>
                {props.t("Close")}
              </Button>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageRent);
