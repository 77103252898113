import axios from "axios";
import { NavLink } from "react-router-dom";
import { BoxappBackend } from "./boxapp_helper";

class BoxappAuthBackend extends BoxappBackend {
  constructor() {
    super();
    this.permissions = [];
  }
  mfaQRCode = async (id) => {
    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/account/${id}/MFA/GOOGLE_AUTHENTICATOR`,
        "Content-Type": "application/json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  mfaLogin = async (token, passcode) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/accounts/mfa/verify",
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: { token: token, passcode: passcode },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getTenant = async (username, password) => {
    try {
      const response = await axios({
        method: "post",
        url: process.env.NODE_ENV === "development" ? process.env.REACT_APP_TENANT_URL : "/auth/tenant",
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: { username: username, password: password },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  exchangeToken = async (token) => {
    try {
      const response = await axios({
        method: "POST",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/auth/token",
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: { token: token },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  /**
   * Login user with given details
   */
  loginUser = async (username, password) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: `${this.baseURL}${process.env.REACT_APP_TENANT_ID ? "/" + process.env.REACT_APP_TENANT_ID : ''}`,
        url: "/auth/login",
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: { username: username, password: password },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  /**
   * Logout the user
   */
  logout = () => {
    localStorage.clear();
    return;
  };


  /**
   * Returns the authenticated user
   */

  getPermissions = () => {
    return { "SUPER": true }

  };
  getAuthenticatedUser = async () => {

    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/auth",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  getAuthPermissions = async () => {

    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/auth/permissions",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  changePassword = async (id, changes) => {
    try {
      const response = await axios({
        method: "put",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/account/${id}/password/change`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: changes,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappAuthBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappAuthBackend = () => {
  if (!_BoxappAuthBackend) {
    _BoxappAuthBackend = new BoxappAuthBackend();
  }
  return _BoxappAuthBackend;
};

export { getBoxappAuthBackend };
