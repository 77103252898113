import { useCallback, useReducer } from "react";
import i18n from "../i18n";
import {
  useGlobalModalContext,
  MODAL_TYPES,
} from "../context/GlobalModalContext";
import {
  getAuth,
  onSignInSubmit,
  RecaptchaVerifier,
  signOut,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from "firebase/auth";

import { getBoxappOrderBackend } from "../helpers/boxapp_order_helper";
import { getBoxappOrderItemBackend } from "../helpers/boxapp_order_item_helper";

import { getBoxappOrderFollowBackend } from "../helpers/boxapp_order_follow_helper";
import { getBoxappContractBackend } from "../helpers/boxapp_contract_helper";
import { getBoxappDashboardBackend } from "../helpers/boxapp_dashboard_helper";
import { getBoxappAuthBackend } from "../helpers/boxapp_auth_helper";
import { getBoxappAccountBackend } from "../helpers/boxapp_account_helper";
import { getBoxappBranchBackend } from "../helpers/boxapp_branch_helper";
import { getBoxappCompanyBackend } from "../helpers/boxapp_company_helper";
import { getBoxappRoleBackend } from "../helpers/boxapp_role_helper";
import { getBoxappCategoryBackend } from "../helpers/boxapp_category_helper";
import { getBoxappFeatureBackend } from "../helpers/boxapp_feature_helper";
import { getBoxappCustomerBackend } from "../helpers/boxapp_customer_helper";
import { getBoxappLeadBackend } from "../helpers/boxapp_lead_helper";
import { getBoxappStorageBackend } from "../helpers/boxapp_storage_helper";
import { getBoxappSingleProductBackend } from "../helpers/boxapp_single_product_helper";
import { getBoxappConsumableProductBackend } from "../helpers/boxapp_consumable_product_helper";
import { getBoxappReportBackend } from "../helpers/boxapp_report_helper";
import { getBoxappInvoiceBackend } from "../helpers/boxapp_invoice_helper";
import { getBoxappScheduleBackend } from "../helpers/boxapp_schedule_helper";
import { getBoxappCustomFieldBackend } from "../helpers/boxapp_custom_field_helper";
import { getBoxappNotificationBackend } from "../helpers/boxapp_notification_helper";
import { getBoxappAppSettingBackend } from "../helpers/boxapp_app_setting_helper";
import { useHistory } from "react-router-dom";
import duration from "../util/duration";
import store from "../util/storage";

export const API_CREATE_ORDERS = "API_CREATE_ORDERS";
export const API_CREATE_DEPOSIT = "API_CREATE_DEPOSIT";

export const API_GET_ORDERS = "API_GET_ORDERS";
export const API_GET_ORDER = "API_GET_ORDER";
export const API_GET_DEPOSIT_ORDER = "API_GET_DEPOSIT_ORDER";
export const API_GET_ORDER_EVENTS = "API_GET_ORDER_EVENTS";
export const API_VOID_ORDER = "API_VOID_ORDER";
export const API_UPDATE_ORDER = "API_UPDATE_ORDER";
export const API_CREATE_PAYMENT = "API_CREATE_PAYMENT";
export const API_UPDATE_PAYMENT = "API_UPDATE_PAYMENT";
export const API_VOID_PAYMENT = "API_VOID_PAYMENT";

export const API_GET_PAID_ITEMS = "API_GET_PAID_ITEMS";
export const API_CREATE_ORDER_ITEMS = "API_CREATE_ORDER_ITEMS";

export const API_DOWNLOAD_ORDER_ATTACHMENT = "API_DOWNLOAD_ORDER_ATTACHMENT";
export const API_UPLOAD_ORDER_ATTACHMENT = "API_UPLOAD_ORDER_ATTACHMENT";
export const API_GET_ORDER_ATTACHMENTS = "API_GET_ORDER_ATTACHMENTS";
export const API_DOWNLOAD_RECEIPTES = "API_DOWNLOAD_RECEIPTES";

export const API_GET_ORDER_FOLLOWS = "API_GET_ORDER_FOLLOWS";
export const API_GET_ORDERS_FOLLOWS = "API_GET_ORDERS_FOLLOWS";
export const API_CREATE_ORDER_FOLLOW = "API_CREATE_ORDER_FOLLOW";

export const API_GET_MASTER_CONTRACT = "API_GET_MASTER_CONTRACT";
export const API_GET_CONTRACT = "API_GET_CONTRACT";
export const API_CREATE_CONTRACT = "API_CREATE_CONTRACT";
export const API_UPDATE_CONTRACT = "API_UPDATE_CONTRACT";
export const API_EXTEND_CONTRACT = "API_EXTEND_CONTRACT";
export const API_END_CONTRACT = "API_END_CONTRACT";

export const API_GET_INVOICE = "API_GET_INVOICE";
export const API_CREATE_INVOICE = "API_CREATE_INVOICE";
export const API_UPDATE_INVOICE = "API_UPDATE_INVOICE";
export const API_VOID_INVOICE = "API_VOID_INVOICE";
export const API_CANCEL_INVOICE = "API_CANCEL_INVOICE";



export const API_CHANGE_PASSWORD = "API_CHANGE_PASSWORD";
export const API_LOGIN = "API_LOGIN";
export const API_MFA_LOGIN = "API_MFA_LOGIN";
export const API_MFA_SEND = "API_MFA_SEND";
export const API_GET_MFA_QRCODE = "API_GET_MFA_QRCODE";

export const API_LOGOUT = "API_LOGOUT";

export const API_GET_AUTH_USER = "API_GET_AUTH_USER";
export const API_GET_AUTH_PERMISSIONS = "API_GET_AUTH_PERMISSIONS";
export const API_GET_ROLES = "API_GET_ROLES";
export const API_GET_PERMISSIONS = "API_GET_PERMISSIONS";
export const API_EDIT_ROLE = "API_EDIT_ROLE";
export const API_CREATE_ROLE = "API_CREATE_ROLE";
export const API_UPDATE_ACCOUNT = "API_UPDATE_ACCOUNT";
export const API_CREATE_ACCOUNT = "API_CREATE_ACCOUNT";
export const API_GET_ACCOUNTS = "API_GET_ACCOUNTS";

export const API_GET_COMPANY_INFO = "API_GET_COMPANY_INFO";
export const API_UPDATE_COMPANY_TANDC = "API_UPDATE_COMPANY_TANDC";
export const API_UPDATE_COMPANY_INFO = "API_UPDATE_COMPANY_INFO";
export const API_UPLOAD_COMPANY_LOGO = "API_UPLOAD_COMPANY_LOGO";

export const API_GET_BRANCH = "API_GET_BRANCH";
export const API_GET_BRANCHES = "API_GET_BRANCHES";
export const API_CREATE_BRANCH = "API_CREATE_BRANCH";
export const API_UPDATE_BRANCH = "API_UPDATE_BRANCH";
export const API_SORT_BRANCH = "API_SORT_BRANCH";
export const API_DISABLE_BRANCH = "API_DISABLE_BRANCH";
export const API_UPDATE_BRANCH_TANDC = "API_UPDATE_BRANCH_TANDC";
export const API_UPLOAD_BRANCH_LOGO = "API_UPLOAD_BRANCH_LOGO";

export const API_GET_CATEGORIES = "API_GET_CATEGORIES";
export const API_CREATE_CATEGORY = "API_CREATE_CATEGORY";
export const API_UPDATE_CATEGORY = "API_UPDATE_CATEGORY";
export const API_GET_FEATURES = "API_GET_FEATURES";
export const API_CREATE_FEATURE = "API_CREATE_FEATURE";
export const API_UPDATE_FEATURE = "API_UPDATE_FEATURE";

export const API_GET_CUSTOMER = "API_GET_CUSTOMER";
export const API_GET_CUSTOMER_STORAGE = "API_GET_CUSTOMER_STORAGE";
export const API_GET_CUSTOMERS = "API_GET_CUSTOMERS";
export const API_CREATE_CUSTOMER = "API_CREATE_CUSTOMER";
export const API_UPDATE_CUSTOMER = "API_UPDATE_CUSTOMER";
export const API_CREATE_WALLET = "API_CREATE_WALLET";
export const API_WITHDRAW_WALLET = "API_WITHDRAW_WALLET";
export const API_TOP_UP_WALLET = "API_TOP_UP_WALLET";
export const API_GET_WALLETS = "API_GET_WALLETS";
export const API_GET_TRANSACTIONS = "API_GET_TRANSACTIONS";

export const API_GET_LEAD = "API_GET_LEAD";
export const API_GET_LEADS = "API_GET_LEADS";
export const API_CREATE_LEAD = "API_CREATE_LEAD";
export const API_UPDATE_LEAD = "API_UPDATE_LEAD";
export const API_CREATE_LEAD_CALL = "API_CREATE_LEAD_CALL";

export const API_GET_STORAGES = "API_GET_STORAGES";
export const API_GET_STORAGE = "API_GET_STORAGE";
export const API_GET_STORAGE_RENT_RECORDS = "API_GET_STORAGE_RENT_RECORDS";
export const API_GET_STORAGE_EVENTS = "API_GET_STORAGE_EVENTS";
export const API_GET_STORAGE_ORDERS = "API_GET_STORAGE_ORDERS";
export const API_CREATE_STORAGE = "API_CREATE_STORAGE";
export const API_CREATE_STORAGES = "API_CREATE_STORAGES";
export const API_UPDATE_STORAGE = "API_UPDATE_STORAGE";
export const API_UPDATE_STORAGES = "API_UPDATE_STORAGES";
export const API_RENT_STORAGE = "API_RENT_STORAGE";
export const API_RENT_CANCEL = "API_RENT_CANCEL";
export const API_RENT_CANCEL_SCHEDULE = "API_RENT_CANCEL_SCHEDULE";
export const API_RENT_CONFIRM = "API_RENT_CONFIRM";
export const API_RENT_EXTEND = "API_RENT_EXTEND";
export const API_TERMINATE_STORAGE = "API_TERMINATE_STORAGE";
export const API_MOVE_STORAGE = "API_MOVE_STORAGE";
export const API_MAINTENANCE_STORAGE = "API_MAINTENANCE_STORAGE";
export const API_RESUME_STORAGE = "API_RESUME_STORAGE";
export const API_RELEASE_STORAGE = "API_RELEASE_STORAGE";
export const API_DONATE_STORAGE_ITEM = "API_DONATE_STORAGE_ITEM";

export const API_GET_SINGLE_PRODUCTS = "API_GET_SINGLE_PRODUCTS";
export const API_CREATE_SINGLE_PRODUCT = "API_CREATE_SINGLE_PRODUCT";
export const API_UPDATE_SINGLE_PRODUCT = "API_UPDATE_SINGLE_PRODUCT";
export const API_GET_SINGLE_PRODUCT = "API_GET_SINGLE_PRODUCT";

export const API_GET_CONSUMABLE_PRODUCTS = "API_GET_CONSUMABLE_PRODUCTS";
export const API_CREATE_CONSUMABLE_PRODUCT = "API_CREATE_CONSUMABLE_PRODUCT";
export const API_UPDATE_CONSUMABLE_PRODUCT = "API_UPDATE_CONSUMABLE_PRODUCT";
export const API_GET_CONSUMABLE_PRODUCT = "API_GET_CONSUMABLE_PRODUCT";

export const API_GET_SCHEDULES = "API_GET_SCHEDULES";
export const API_GET_SCHEDULE = "API_GET_SCHEDULE";
export const API_RUN_SCHEDULE = "API_RUN_SCHEDULE";
export const API_CANCEL_SCHEDULES = "API_CANCEL_SCHEDULES";

export const API_REPORT_STORAGES = "API_REPORT_STORAGES";
export const API_REPORT_ORDERS = "API_REPORT_ORDERS";
export const API_REPORT_MONTHLY = "API_REPORT_MONTHLY";
export const API_REPORT_CONTRACTS = "API_REPORT_CONTRACTS";

export const API_GET_NOTIFICATIONS = "API_GET_NOTIFICATIONS";
export const API_UPDATE_NOTIFICATIONS = "API_UPDATE_NOTIFICATIONS";

export const API_GET_CUSTOM_FIELDS = "API_GET_CUSTOM_FIELDS";
export const API_CREATE_CUSTOM_FIELD = "API_CREATE_CUSTOM_FIELD";
export const API_UPDATE_CUSTOM_FIELD = "API_UPDATE_CUSTOM_FIELD";

export const API_GET_DASHBOARD_MONTHLY_REVENUE =
  "API_GET_DASHBOARD_MONTHLY_REVENUE";
export const API_GET_DASHBOARD_LATEST_ORDER_STATUS =
  "API_GET_DASHBOARD_LATEST_ORDER_STATUS";
export const API_GET_DASHBOARD_LATEST_MONTHLY_ORDER =
  "API_GET_DASHBOARD_LATEST_MONTHLY_ORDER";
export const API_GET_DASHBOARD_LATEST_STORAGE_STATUS =
  "API_GET_DASHBOARD_LATEST_STORAGE_STATUS";

export const API_GET_SETTINGS = "API_GET_SETTINGS";
export const API_SET_SETTINGS = "API_SET_SETTINGS";

export const FETCHING = `FETCHING`;
export const SUCCESS = `SUCCESS`;
export const ERROR = `ERROR`;

export const INIT_STATE = {
  status: null,
  response: null,
};
export const fetching = () => ({ type: FETCHING });
export const success = (response) => ({ type: SUCCESS, response });
export const error = (response) => ({ type: ERROR, response });
export const reducer = (state = INIT_STATE, { type, response }) => {
  switch (type) {
    case FETCHING:
      return { ...INIT_STATE, status: FETCHING };
    case SUCCESS:
      return { ...state, status: SUCCESS, response };
    case ERROR:
      return { ...state, status: ERROR, response };
    default:
      return state;
  }
};

const UseAPI = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const { showModal, loading, setLoading } = useGlobalModalContext();
  const BoxappOrderBackend = getBoxappOrderBackend();
  const BoxappOrderFollowBackend = getBoxappOrderFollowBackend();
  const BoxappContractBackend = getBoxappContractBackend();
  const BoxappOrderItemBackend = getBoxappOrderItemBackend();
  const BoxappDashboardBackend = getBoxappDashboardBackend();
  const BoxappAuthBackend = getBoxappAuthBackend();
  const BoxappAccountBackend = getBoxappAccountBackend();
  const BoxappRoleBackend = getBoxappRoleBackend();
  const BoxappCategoryBackend = getBoxappCategoryBackend();
  const BoxappFeatureBackend = getBoxappFeatureBackend();
  const BoxappCustomerBackend = getBoxappCustomerBackend();
  const BoxappLeadBackend = getBoxappLeadBackend();
  const BoxappStorageBackend = getBoxappStorageBackend();
  const BoxappSingleProductBackend = getBoxappSingleProductBackend();
  const BoxappConsumableProductBackend = getBoxappConsumableProductBackend();
  const BoxappScheduleBackend = getBoxappScheduleBackend();
  const BoxappBranchBackend = getBoxappBranchBackend();
  const BoxappCompanyBackend = getBoxappCompanyBackend();
  const BoxappReportBackend = getBoxappReportBackend();
  const BoxappInvoiceBackend = getBoxappInvoiceBackend();
  const BoxappCustomFieldBackend = getBoxappCustomFieldBackend();
  const BoxappNotificationBackend = getBoxappNotificationBackend();
  const BoxappAppSettingBackend = getBoxappAppSettingBackend();

  const getPayloadValue = async (key, ...payload) => {
    const [first, ...last] = payload;
    return first[key];
  };
  const APICallStack = [];
  const makeRequest = useCallback(async (action, ...payload) => {
    const err = {
      data: {},
    };
    let successMsg = null;
    const apiTimeOut = () => {
      APICallStack.pop();
      if (APICallStack.length === 0) {
        setLoading(false);
      } else {
        APICallStack.splice(0, APICallStack.lastIndex);
        setLoading(false);
      }
    };
    try {
      dispatch(fetching());
      setLoading(true);
      if (!APICallStack[action]) {
        APICallStack.push(action);
      } else {
        return;
      }

      switch (action) {
        case API_GET_MASTER_CONTRACT:
          {
            const { master_contract } =
              await BoxappContractBackend.getMasterContract(...payload);
            if (master_contract) {
              dispatch(
                success({
                  masterContract: master_contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get master contract failed";
              throw err;
            }
          }
          break;
        case API_GET_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.getContract(
              ...payload
            );
            if (contract) {
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get contract failed";
              throw err;
            }
          }
          break;
        case API_CREATE_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.create(...payload);
            if (contract) {
              successMsg = "create contract success";
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create contract failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.update(...payload);
            if (contract) {
              successMsg = "contract update success";
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create update failed";
              throw err;
            }
          }
          break;

        case API_EXTEND_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.extend(...payload);
            if (contract) {
              successMsg = "extend contract success";
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "extend contract failed";
              throw err;
            }
          }
          break;
        case API_END_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.end(...payload);
            if (contract) {
              successMsg = "end contract success";
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "end contract failed";
              throw err;
            }
          }
          break;
        case API_CREATE_ORDER_FOLLOW:
          {
            const { follow } = await BoxappOrderFollowBackend.createOrderFollow(
              ...payload
            );
            if (follow) {
              successMsg = "create order follow success";
              dispatch(
                success({
                  follow: follow,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create order follow failed";
              throw err;
            }
          }
          break;
        case API_GET_ORDERS_FOLLOWS:
          {
            const { follows, orders } =
              await BoxappOrderFollowBackend.getBranchOrdersFollows(...payload);
            if (follows && orders) {
              dispatch(success({ follows: follows, orders: orders }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get order follows failed";
              throw err;
            }
          }
          break;
        case API_GET_ORDER_FOLLOWS:
          {
            const { follows } = await BoxappOrderFollowBackend.getOrderFollows(
              ...payload
            );
            if (follows) {
              dispatch(success({ follows: follows }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get order follows failed";
              throw err;
            }
          }
          break;
        case API_GET_DEPOSIT_ORDER:
        case API_GET_ORDER:
          if (payload[2] && payload[2] === "Deposit") {
            const { deposit_order } = await BoxappOrderBackend.getOrder(
              ...payload
            );
            if (deposit_order) {
              dispatch(success({ depositOrder: deposit_order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get depositorder failed";
              throw err;
            }
          } else {
            const { order } = await BoxappOrderBackend.getOrder(...payload);
            if (order) {
              dispatch(success({ order: order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get order failed";
              throw err;
            }
          }
          break;
        case API_CREATE_DEPOSIT:
          {
            const { deposit_order } =
              await BoxappOrderBackend.createDepositOrder(...payload);
            if (deposit_order) {
              dispatch(success({ deposit_order: deposit_order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get deposit orders failed";
              throw err;
            }
          }
          break;
        case API_GET_ORDERS:
          {
            const { orders } = await BoxappOrderBackend.getOrders(...payload);
            if (orders) {
              dispatch(success({ orders: orders }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get orders failed";
              throw err;
            }
          }
          break;

        case API_GET_ORDER_EVENTS:
          {
            //id
            const { events } = await BoxappOrderBackend.getOrderEvents(
              ...payload
            );
            if (events) {
              dispatch(success({ events: events }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get events failed";
              throw err;
            }
          }
          break;
        case API_VOID_ORDER:
          {
            const { order } = await BoxappOrderBackend.void(...payload);
            if (order) {
              successMsg = "void order success";
              dispatch(success({ order: order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "void order failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_ORDER:
          {
            const { order, deposit_order } =
              await BoxappOrderBackend.updateOrder(...payload);
            if (payload[2]) {
              if (payload[2] === "Deposit") {
                if (deposit_order) {
                  dispatch(success({ deposit_order: deposit_order }));
                  APICallStack.pop();
                  if (APICallStack.length === 0) {
                    setLoading(false);
                  }
                  successMsg = "update order success";
                } else {
                  err.data.message = "update order failed";
                  throw err;
                }
              } else {
                if (order) {
                  dispatch(success({ order: order }));
                  APICallStack.pop();
                  if (APICallStack.length === 0) {
                    setLoading(false);
                  }
                  successMsg = "update order success";
                } else {
                  err.data.message = "update order failed";
                  throw err;
                }
              }
            } else {
              if (order) {
                dispatch(success({ order: order }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                successMsg = "update order success";
              } else {
                err.data.message = "update order failed";
                throw err;
              }
            }
          }
          break;
        case API_CREATE_PAYMENT:
          {
            const { payment, storage } = await BoxappOrderBackend.createPayment(
              ...payload
            );
            if (payment) {
              dispatch(success({ payment: payment, storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update payment success";
            } else {
              err.data.message = "update payment failed";
              throw err;
            }
          }
          break;
        case API_VOID_PAYMENT:
          {
            const { payment, storage } = await BoxappInvoiceBackend.voidPayment(
              ...payload
            );
            if (payment) {
              dispatch(success({ payment: payment, storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update payment success";
            } else {
              err.data.message = "update payment failed";
              throw err;
            }
          }
          break;

        case API_UPDATE_PAYMENT:
          {
            const { payment, storage } = await BoxappOrderBackend.updatePayment(
              ...payload
            );
            if (payment) {
              dispatch(success({ payment: payment, storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update payment success";
            } else {
              err.data.message = "update payment failed";
              throw err;
            }
          }
          break;

        case API_GET_ORDER_ATTACHMENTS: {
          if (!payload[2]) {
            throw new Error("incorrect param");
          }
          const { attachments } = await BoxappOrderBackend.getOrderAttachments(
            ...payload
          );
          if (attachments) {
            dispatch(success({ attachments: attachments }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get attachments failed";
            throw err;
          }
          break;
        }
        case API_DOWNLOAD_RECEIPTES: {
          const receipt = await BoxappOrderBackend.getReceipt(...payload);
          if (receipt) {
            dispatch(success({ receipt: receipt }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "download failed";
            throw err;
          }
          break;
        }
        case API_UPLOAD_ORDER_ATTACHMENT: {
          const { attachment } = await BoxappOrderBackend.uploadOrderAttachment(
            ...payload
          );
          if (attachment) {
            dispatch(success({ attachment: attachment }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
            successMsg = "upload success";
          } else {
            err.data.message = "upload failed";
            throw err;
          }
          break;
        }
        case API_DOWNLOAD_ORDER_ATTACHMENT:
          {
            const attachment = await BoxappOrderBackend.getOrderAttachment(
              ...payload
            );
            if (attachment) {
              dispatch(success({ download: attachment }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "download failed";
              throw err;
            }
          }
          break;
        case API_CREATE_ORDER_ITEMS:
          {
            const { paid_items } = await BoxappOrderItemBackend.createOrderItem(
              ...payload
            );
            if (paid_items) {
              dispatch(success({ paidItems: paid_items }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "create order items success";
            } else {
              err.data.message = "create order items failed";
              throw err;
            }
          }
          break;
        case API_GET_PAID_ITEMS:
          {
            const { paid_items } = await BoxappOrderItemBackend.getOrderItems(
              ...payload
            );
            if (paid_items) {
              dispatch(success({ paidItems: paid_items }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "get order items success";
            } else {
              err.data.message = "get order items failed";
              throw err;
            }
          }
          break;
        case API_GET_INVOICE:
          {
            const { invoice, order } = await BoxappInvoiceBackend.getInvoice(
              ...payload
            );
            if (invoice && order) {
              dispatch(success({ invoice: invoice, order: order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "download failed";
              throw err;
            }
          }
          break;
        case API_CREATE_INVOICE:
          {
            const { invoice } = await BoxappInvoiceBackend.createInvoice(
              ...payload
            );
            if (invoice) {
              dispatch(success({ invoice: invoice }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "create invoice success";
            } else {
              err.data.message = "create invoice failed";
              throw err;
            }
          }
          break;
        case API_CANCEL_INVOICE:
          {
            const { invoice } = await BoxappInvoiceBackend.cancelInvoice(
              ...payload
            );
            if (invoice) {
              dispatch(success({ invoice: invoice }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "cancel invoice success";
            } else {
              err.data.message = "cancel invoice failed";
              throw err;
            }
          }
          break;
        case API_VOID_INVOICE:
          {
            const { invoice } = await BoxappInvoiceBackend.voidInvoice(
              ...payload
            );
            if (invoice) {
              dispatch(success({ invoice: invoice }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "void invoice success";
            } else {
              err.data.message = "void invoice failed";
              throw err;
            }
          }
          break;
        case API_CREATE_ACCOUNT:
          const { account } = await BoxappAccountBackend.createAccount(
            ...payload
          );
          if (account) {
            store.removeItem("accounts");
            successMsg = "create account success";
            dispatch(success({ account: account }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "create account failed";
            throw err;
          }

          break;
        case API_UPDATE_ACCOUNT:
          {
            const { account } = await BoxappAccountBackend.updateAccount(
              ...payload
            );
            if (account) {
              store.removeItem("accounts");
              successMsg = "update account success";
              dispatch(success({ account: account }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update account failed";
              throw err;
            }
          }
          break;
        case API_GET_ROLES:
          {
            if (!payload) {
              const cache = store.getWithExpiry("roles");
              if (cache) {
                dispatch(success({ roles: cache }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                return;
              }
            }
            const { roles } = await BoxappRoleBackend.getRoles(...payload);
            if (roles) {
              if (!payload) {
                store.setWithExpiry("roles", roles, duration.minute * 6);
              }
              dispatch(success({ roles: roles }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get roles failed";
              throw err;
            }
          }
          break;
        case API_GET_PERMISSIONS:
          {
            const cache = store.getWithExpiry("permissions");
            if (cache) {
              dispatch(success({ permissions: cache }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              return;
            }
            const { permissions } = await BoxappRoleBackend.getPermissions();
            if (permissions) {
              store.setWithExpiry(
                "permissions",
                permissions,
                duration.minute * 6
              );
              dispatch(success({ permissions: permissions }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get permissions failed";
              throw err;
            }
          }
          break;
        case API_EDIT_ROLE:
          {
            const { role } = await BoxappRoleBackend.updateRole(...payload);
            if (role) {
              store.removeItem("roles");
              dispatch(success({ role: role }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update role Success";
            } else {
              err.data.message = "update role  failed";
              throw err;
            }
          }
          break;
        case API_CREATE_ROLE:
          {
            const { role } = await BoxappRoleBackend.createRole(...payload);
            if (role) {
              store.removeItem("roles");
              dispatch(success({ role: role }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "create role Success";
            } else {
              err.data.message = "create role  failed";
              throw err;
            }
          }
          break;
        case API_GET_ACCOUNTS:
          {

            if (!payload) {
              const cache = store.getWithExpiry("accounts");
              if (cache) {
                dispatch(success({ accounts: cache }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                return;
              }
            }

            const { accounts } = await BoxappAccountBackend.getAccounts(...payload);
            if (accounts) {
              if (!payload) {
                store.setWithExpiry("accounts", accounts, duration.minute * 6);
              }

              dispatch(success({ accounts: accounts }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get accounts failed";
              throw err;
            }
          }
          break;
        case API_GET_MFA_QRCODE: {
          const { QRCode } = await BoxappAuthBackend.mfaQRCode(...payload);
          dispatch(
            success({
              QRCode: QRCode,
            })
          );
          APICallStack.pop();
          if (APICallStack.length === 0) {
            setLoading(false);
          }
          break;
        }
        case API_MFA_SEND: {
          const firebaseAuth = getAuth();
          firebaseAuth.tenantId = process.env.REACT_APP_TENANT_ID;
          const { confirm } = await signInWithPhoneNumber(
            firebaseAuth,
            ...payload,
            window.recaptchaVerifier
          );
          console.log(confirm);
          break;
        }
        case API_MFA_LOGIN: {
          const { token, authUser } = await BoxappAuthBackend.mfaLogin(
            ...payload
          );
          if (token) {
            localStorage.setItem("app-namespace", authUser.app);
            localStorage.setItem("token", token.access_token);
            const { permissions } =
              await BoxappAuthBackend.getAuthPermissions();
            i18n.setDefaultNamespace(authUser.app);
            dispatch(
              success({
                token: token,
                authUser: {
                  ...authUser,
                  role: {
                    ...authUser.role,
                    permissions: permissions,
                  },
                },
              })
            );
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "token failed";
            throw err;
          }
          break;
        }
        case API_LOGIN:
          try {
            const firebaseAuth = getAuth();
            firebaseAuth.tenantId = process.env.REACT_APP_TENANT_ID;

            const { user } = await signInWithEmailAndPassword(
              firebaseAuth,
              ...payload
            );
            if (user) {
              try {
                const { token, authUser, MFA, auth_token } =
                  await BoxappAuthBackend.exchangeToken(
                    user.stsTokenManager.accessToken
                  );
                if (token) {
                  localStorage.setItem("app-namespace", authUser.app);
                  localStorage.setItem("token", token.access_token);
                  const { permissions } =
                    await BoxappAuthBackend.getAuthPermissions();

                  i18n.setDefaultNamespace(authUser.app);
                  dispatch(
                    success({
                      token: token,
                      authUser: {
                        ...authUser,
                        role: {
                          ...authUser?.role,
                          permissions: permissions,
                        },
                      },
                    })
                  );
                  APICallStack.pop();
                  if (APICallStack.length === 0) {
                    setLoading(false);
                  }
                } else {
                  dispatch(
                    success({
                      MFA: MFA,
                      authToken: auth_token,
                    })
                  );
                }
              } catch (err) {
                console.log(err);
                err.data.message = "invalid access";
                throw err;
              }
            }
          } catch (err) {
            console.log(err);
            if (!err?.data) {
              err.data = {}
            }
            err.data.message = "Login failed";
            throw err;
          }

          // const { token } = await BoxappAuthBackend.loginUser(...payload);
          // if (token) {
          //   dispatch(success({ token: token }));
          //   APICallStack.pop()
          //   if (APICallStack.length === 0) {
          //     setLoading(false)
          //   }
          // } else {
          //   err.data.message = "Login failed"
          //   throw (err)
          // }
          break;

        case API_LOGOUT:
          try {
            const auth = getAuth();
            await signOut(auth);
            localStorage.clear();
            dispatch(success({ token: null, authUser: null }));
          } catch (e) {
            err.data.message = "Logout failed";
            throw err;
          }
          // await BoxappAuthBackend.logout();

          break;
        case API_GET_COMPANY_INFO:
          {
            const cache = store.getWithoutExpiry("company");
            if (cache) {
              dispatch(success({ company: cache }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              return;
            }
            const { company } = await BoxappCompanyBackend.getCompany(
              ...payload
            );
            if (company) {
              store.setWithoutExpiry("company", company);
              dispatch(success({ company: company }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get company failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_COMPANY_INFO:
          {
            const { company } = await BoxappCompanyBackend.updateCompany(
              ...payload
            );
            if (company) {
              store.removeItem("company");
              dispatch(success({ company: company }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update company Success";
            } else {
              err.data.message = "update company failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_COMPANY_TANDC:
          {
            const { company } = await BoxappCompanyBackend.updateCompanyTandC(
              ...payload
            );
            if (company) {
              store.removeItem("company");
              dispatch(success({ company: company }));
              successMsg = "update company Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update company failed";
              throw err;
            }
          }
          break;

        case API_UPLOAD_COMPANY_LOGO:
          {
            store.removeItem("company");
            const { company } = await BoxappCompanyBackend.updateCompanyLogo(
              ...payload
            );
            if (company) {
              successMsg = "Upload Success";
              dispatch(success({ company: company }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update company logo failed";
              throw err;
            }
          }
          break;
        case API_GET_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.getBranch(...payload);
            if (branch) {
              dispatch(success({ branch: branch }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get branch failed";
              throw err;
            }
          }
          break;
        case API_GET_BRANCHES:
          {
            const cache = store.getWithExpiry("branches");
            if (cache) {
              dispatch(success({ branches: cache }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              return;
            }
            const { branches } = await BoxappBranchBackend.getBranches();
            if (branches) {
              store.setWithExpiry("branches", branches, duration.second * 2);
              dispatch(success({ branches: branches }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get branches failed";
              throw err;
            }
          }
          break;
        case API_CREATE_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.createBranch(
              ...payload
            );
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "create branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create branch failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_BRANCH_TANDC:
          {
            const { branch } = await BoxappBranchBackend.updateBranchTandC(
              ...payload
            );
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "update branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update branch failed";
              throw err;
            }
          }
          break;
        case API_DISABLE_BRANCH: {
          const { branch } = await BoxappBranchBackend.disableBranch(
            ...payload
          );
          if (branch) {
            store.removeItem("branches");
            dispatch(success({ branch: branch }));
            successMsg = "update branch Success";
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "update branch failed";
            throw err;
          }
        }
          break
        case API_SORT_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.sortBranch(
              ...payload
            );
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "sort branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "sort branch failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.updateBranch(
              ...payload
            );
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "update branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update branch failed";
              throw err;
            }
          }
          break;
        case API_UPLOAD_BRANCH_LOGO: {
          const { branch } = await BoxappBranchBackend.uploadBranchLogo(
            ...payload
          );
          if (branch) {
            dispatch(success({ branch: branch }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
            successMsg = "upload success";
          } else {
            err.data.message = "upload failed";
            throw err;
          }
          break;
        }
        case API_GET_AUTH_USER: {
          // if (authUser) {
          //   dispatch(success({ authUser: authUser }));
          //    APICallStack.pop()
          if (APICallStack.length === 0) {
            setLoading(false);
          }
          //   return authUser;
          // }
          const { account } = await BoxappAuthBackend.getAuthenticatedUser(
            ...payload
          );
          if (account) {
            dispatch(success({ authUser: account }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get auth user failed";
            throw err;
          }
          break;
        }
        case API_GET_AUTH_PERMISSIONS: {
          const { permissions } = await BoxappAuthBackend.getAuthPermissions();
          if (permissions) {
            dispatch(success({ permissions: permissions }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get permissions failed";
            throw err;
          }
          break;
        }
        case API_CHANGE_PASSWORD:
          {
            const { token } = await BoxappAuthBackend.changePassword(
              ...payload
            );
            if (token) {
              successMsg = "Update password Success";
              dispatch(success({ token: token }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "Change Password  failed";
              throw err;
            }
          }
          break;
        case API_GET_CATEGORIES:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const is_filter = await getPayloadValue("is_filter", ...payload);
            const key =
              branch_id + "_categories" + (is_filter ? "_filter" : "");
            if (branch_id) {
              const cache = store.getWithExpiry(key);
              if (cache) {
                dispatch(success({ categories: cache }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                return;
              }
            }

            const { categories } = await BoxappCategoryBackend.getCategories(
              ...payload
            );
            if (categories) {
              store.setWithExpiry(key, categories, duration.minute * 2);
              dispatch(success({ categories: categories }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get categories failed";
              throw err;
            }
          }
          break;
        case API_CREATE_CATEGORY:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const is_filter = await getPayloadValue("is_filter", ...payload);
            const key =
              branch_id + "_categories" + (is_filter ? "_filter" : "");
            const { category } = await BoxappCategoryBackend.createCategory(
              ...payload
            );
            if (category) {
              store.removeItem(key);
              successMsg = "create category Success";
              dispatch(success({ category: category }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create category failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_CATEGORY:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const is_filter = await getPayloadValue("is_filter", ...payload);
            const key =
              branch_id + "_categories" + (is_filter ? "_filter" : "");
            const { category } = await BoxappCategoryBackend.updateCategory(
              ...payload
            );
            if (category) {
              store.removeItem(key);
              successMsg = "update category Success";
              dispatch(success({ category: category }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update category failed";
              throw err;
            }
          }
          break;
        case API_GET_FEATURES:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const is_filter = await getPayloadValue("is_filter", ...payload);
            const key = branch_id + "_features" + (is_filter ? "_filter" : "");
            if (branch_id) {
              const cache = store.getWithExpiry(key);
              if (cache) {
                dispatch(success({ features: cache }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                return;
              }
            }

            const { features } = await BoxappFeatureBackend.getFeatures(
              ...payload
            );
            if (features) {
              store.setWithExpiry(key, features, duration.minute * 2);
              dispatch(success({ features: features }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get features failed";
              throw err;
            }
          }
          break;
        case API_CREATE_FEATURE:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const filter = await getPayloadValue("is_filter", ...payload);
            const key = branch_id + "_features" + (filter ? "_filter" : "");
            const { feature } = await BoxappFeatureBackend.createFeature(
              ...payload
            );
            if (feature) {
              store.removeItem(key);
              successMsg = "create feature Success";
              dispatch(success({ feature: feature }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create feature failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_FEATURE:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const filter = await getPayloadValue("is_filter", ...payload);
            const key = branch_id + "_features" + (filter ? "_filter" : "");
            const { feature } = await BoxappFeatureBackend.updateFeature(
              ...payload
            );
            if (feature) {
              store.removeItem(key);
              successMsg = "update feature Success";
              dispatch(success({ feature: feature }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update feature failed";
              throw err;
            }
          }
          break;
        case API_GET_CUSTOMER:
          {
            const { customer } =
              await BoxappCustomerBackend.getCustomer(...payload);
            if (customer) {
              dispatch(success({ customer: customer }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get customer failed";
              throw err;
            }
          }
          break;
        case API_GET_CUSTOMER_STORAGE:
          {
            const { storages } =
              await BoxappCustomerBackend.getCustomerStorages(...payload);
            if (storages) {
              dispatch(success({ storages: storages }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get customer failed";
              throw err;
            }
          }
          break;

        case API_GET_CUSTOMERS:
          {
            const { customers } = await BoxappCustomerBackend.getCustomers(
              ...payload
            );
            if (customers) {
              dispatch(success({ customers: customers }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get customers failed";
              throw err;
            }
          }
          break;
        case API_CREATE_CUSTOMER:
          {
            const { customer } = await BoxappCustomerBackend.createCustomer(
              ...payload
            );
            if (customer) {
              successMsg = "create customer Success";
              dispatch(success({ customer: customer }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create customer failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_CUSTOMER:
          {
            const { customer } = await BoxappCustomerBackend.updateCustomer(
              ...payload
            );
            if (customer) {
              successMsg = "update customer Success";
              dispatch(success({ customer: customer }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update customer failed";
              throw err;
            }
          }
          break;
        case API_CREATE_WALLET:
          {
            const { wallet } = await BoxappCustomerBackend.createWallet(
              ...payload
            );
            if (wallet) {
              const customerId = payload?.[0]
              if (customerId) {
                store.removeItem(`${customerId}/wallets`)
              }
              successMsg = "update wallet Success";
              dispatch(success({ wallet: wallet }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update wallet failed";
              throw err;
            }
          }
          break;
        case API_TOP_UP_WALLET: {
          const { wallet } = await BoxappCustomerBackend.topup(
            ...payload
          );
          if (wallet) {
            successMsg = "topup wallet Success";
            dispatch(success({ wallet: wallet }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "topup wallet failed";
            throw err;
          }
        }
          break
        case API_WITHDRAW_WALLET: {
          const { wallet } = await BoxappCustomerBackend.withdraw(
            ...payload
          );
          if (wallet) {
            successMsg = "withdraw wallet Success";
            dispatch(success({ wallet: wallet }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "withdraw wallet failed";
            throw err;
          }
        }
          break
        case API_GET_WALLETS:
          {
            const { wallets } = await BoxappCustomerBackend.getWallets(
              ...payload
            );
            if (wallets) {
              // successMsg = "update wallets Success";
              dispatch(success({ wallets: wallets }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update wallets failed";
              throw err;
            }
          }
          break;

        case API_GET_TRANSACTIONS:
          {
            const { transactions } = await BoxappCustomerBackend.getTransactions(
              ...payload
            );
            if (transactions) {
              // successMsg = "update transactions Success";
              dispatch(success({ transactions: transactions }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update transactions failed";
              throw err;
            }
          }
          break;

        case API_GET_LEAD:
          {
            const { lead } = await BoxappLeadBackend.getLead(...payload);
            if (lead) {
              dispatch(success({ lead: lead }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get lead failed";
              throw err;
            }
          }
          break;
        case API_GET_LEADS:
          {
            const { leads } = await BoxappLeadBackend.getLeads(...payload);
            if (leads) {
              dispatch(success({ leads: leads }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get leads failed";
              throw err;
            }
          }
          break;
        case API_CREATE_LEAD:
          {
            const { lead } = await BoxappLeadBackend.createLead(...payload);
            if (lead) {
              successMsg = "create lead Success";
              dispatch(success({ lead: lead }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create lead failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_LEAD:
          {
            const { lead } = await BoxappLeadBackend.updateLead(...payload);
            if (lead) {
              successMsg = "update lead Success";
              dispatch(success({ lead: lead }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update customer failed";
              throw err;
            }
          }
          break;
        case API_CREATE_LEAD_CALL:
          {
            const { lead_call } = await BoxappLeadBackend.createLeadCall(
              ...payload
            );
            if (lead_call) {
              successMsg = "create lead call Success";
              dispatch(success({ leadCall: lead_call }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create lead call failed";
              throw err;
            }
          }
          break;
        case API_GET_STORAGES:
          {
            const branch_id = payload?.[0];
            const key = branch_id + "_storages";
            if (branch_id) {
              if (payload?.[1]?.query) {
                const cache = store.getWithExpiry(key);
                if (cache) {
                  dispatch(success({ storages: cache }));
                  APICallStack.pop();
                  if (APICallStack.length === 0) {
                    setLoading(false);
                  }
                  return
                }
              }

              const { storages } = await BoxappStorageBackend.getStorages(
                ...payload
              );
              if (storages) {
                if (payload?.[1]?.query) {
                  store.setWithExpiry(key, storages, duration.minute * 2);
                }
                dispatch(success({ storages: storages }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
              } else {
                err.data.message = "get storages failed";
                throw err;
              }

            }
          }
          break;
        case API_GET_STORAGE:
          {
            //id
            const { storage } = await BoxappStorageBackend.getStorage(
              ...payload
            );
            if (storage) {
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get rental location failed";
              throw err;
            }
          }
          break;
        case API_GET_STORAGE_RENT_RECORDS:
          {
            //id
            const { rent_records } =
              await BoxappStorageBackend.getStorageRentRecords(...payload);
            if (rent_records) {
              dispatch(success({ rent_records: rent_records }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get rent records failed";
              throw err;
            }
          }
          break;
        case API_GET_STORAGE_EVENTS:
          {
            //id
            const { events } = await BoxappStorageBackend.getStorageEvents(
              ...payload
            );
            if (events) {
              dispatch(success({ events: events }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get events failed";
              throw err;
            }
          }
          break;
        case API_GET_STORAGE_ORDERS:
          {
            //id
            const { orders } = await BoxappStorageBackend.getStorageOrders(
              ...payload
            );
            if (orders) {
              dispatch(success({ orders: orders }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get orders failed";
              throw err;
            }
          }
          break;
        case API_CREATE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.createStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "create rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create rental location failed";
              throw err;
            }
          }
          break;
        case API_CREATE_STORAGES:
          {
            const { storages, storage } =
              await BoxappStorageBackend.createStorage(...payload);
            if (storages || storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "create storages Success";
              dispatch(success({ storages: storages, storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "create storages failed";
              throw err;
            }
          }
          break;
        case API_UPDATE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.updateStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "update rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update rental location failed";
              throw err;
            }
          }
          break;

        case API_UPDATE_STORAGES:
          {
            const { storages } = await BoxappStorageBackend.bulkUpdateStorage(
              ...payload
            );
            if (storages) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "update storages Success";
              dispatch(success({ storages: storages }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "update storages failed";
              throw err;
            }
          }
          break;
        case API_RENT_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.rentStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              successMsg = "rent rental location Success";
              dispatch(success({ storage: storage }));
              if (branch_id) {
                const key = branch_id + "_storages";
                store?.removeItem(key);
              }
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "rent rental location failed";
              throw err;
            }
          }
          break;
        case API_RENT_CANCEL_SCHEDULE:
          {
            const { storage } = await BoxappStorageBackend.cancelSchedule(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "rent cancel Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "rent cancel failed";
              throw err;
            }
          }
          break;
        case API_RENT_CANCEL:
          {
            const { storage } = await BoxappStorageBackend.cancel(...payload);
            if (storage) {
              const branch_id = payload?.[0];
              console.log(payload);
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "rent cancel Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "rent cancel failed";
              throw err;
            }
          }
          break;
        case API_RENT_CONFIRM:
          {
            const { storage } = await BoxappStorageBackend.confirmRentStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "rent confirm Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "rent confrim failed";
              throw err;
            }
          }
          break;
        case API_RENT_EXTEND:
          {
            const { storage } = await BoxappStorageBackend.extendRentStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "rent extend Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "rent extend failed";
              throw err;
            }
          }
          break;
        case API_TERMINATE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.terminateRentStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "terminate rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "terminate rental location failed";
              throw err;
            }
          }
          break;
        case API_MAINTENANCE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.maintenanceStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "Maintenance rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "Maintenance rental location Failed";
              throw err;
            }
          }
          break;
        case API_RELEASE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.releaseStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "Resume rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "Resume rental location failed";
              throw err;
            }
          }
          break;
        case API_RESUME_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.resumeStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "Resume rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "Resume rental location failed";
              throw err;
            }
          }
          break;
        case API_GET_SCHEDULE:
          {
            const { schedule } = await BoxappScheduleBackend.getSchedule(
              ...payload
            );
            if (schedule) {
              dispatch(success({ schedule: schedule }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get schedule failed";
              throw err;
            }
          }

          break;
        case API_RUN_SCHEDULE:
          {
            const { schedule } = await BoxappScheduleBackend.runSchedule(
              ...payload
            );
            if (schedule) {
              dispatch(success({ schedule: schedule }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get schedule failed";
              throw err;
            }
          }

          break;
        case API_GET_SCHEDULES:
          {
            const { schedules } = await BoxappScheduleBackend.getSchedules(
              ...payload
            );
            if (schedules) {
              dispatch(success({ schedules: schedules }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get schedules failed";
              throw err;
            }
          }

          break;

        case API_CANCEL_SCHEDULES:
          {
            const { schedule } = await BoxappScheduleBackend.cancelSchedule(
              ...payload
            );
            if (schedule) {
              dispatch(success({ schedule: schedule }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "cancel schedule failed";
              throw err;
            }
          }

          break;
        case API_REPORT_STORAGES: {
          const { report } = await BoxappReportBackend.storagesReport(
            ...payload
          );
          if (report) {
            dispatch(success({ report: report }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get report failed";
            throw err;
          }
          break;
        }
        case API_CREATE_SINGLE_PRODUCT: {
          const { single_product } =
            await BoxappSingleProductBackend.createSingleProduct(...payload);
          if (single_product) {
            successMsg = "create item Success";
            dispatch(success({ singleProduct: single_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "create single product failed";
            throw err;
          }
          break;
        }
        case API_UPDATE_SINGLE_PRODUCT: {
          const { single_product } =
            await BoxappSingleProductBackend.updateSingleProduct(...payload);
          if (single_product) {
            successMsg = "Update Item Success";
            dispatch(success({ singleProduct: single_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "update single product failed";
            throw err;
          }
          break;
        }
        case API_GET_SINGLE_PRODUCTS: {
          const { single_products } =
            await BoxappSingleProductBackend.getSingleProducts(...payload);
          if (single_products) {
            dispatch(success({ singleProducts: single_products }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get single products failed";
            throw err;
          }
          break;
        }
        case API_GET_SINGLE_PRODUCT: {
          const { single_product } =
            await BoxappSingleProductBackend.updateSingleProduct(...payload);
          if (single_product) {
            dispatch(success({ singleProduct: single_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get single product failed";
            throw err;
          }
          break;
        }

        case API_CREATE_CONSUMABLE_PRODUCT: {
          const { consumable_product } =
            await BoxappConsumableProductBackend.createConsumableProduct(
              ...payload
            );
          if (consumable_product) {
            successMsg = "create item Success";
            dispatch(success({ consumableProduct: consumable_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "create consumable product failed";
            throw err;
          }
          break;
        }
        case API_UPDATE_CONSUMABLE_PRODUCT: {
          const { consumable_product } =
            await BoxappConsumableProductBackend.updateConsumableProduct(
              ...payload
            );
          if (consumable_product) {
            successMsg = "Update Item Success";
            dispatch(success({ consumableProduct: consumable_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "update consumable product failed";
            throw err;
          }
          break;
        }
        case API_GET_CONSUMABLE_PRODUCTS: {
          const { consumable_products } =
            await BoxappConsumableProductBackend.getConsumableProducts(
              ...payload
            );
          if (consumable_products) {
            dispatch(success({ consumableProducts: consumable_products }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get consumable products failed";
            throw err;
          }
          break;
        }
        case API_GET_CONSUMABLE_PRODUCT: {
          const { consumable_product } =
            await BoxappConsumableProductBackend.updateConsumableProduct(
              ...payload
            );
          if (consumable_product) {
            dispatch(success({ consumableProduct: consumable_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get consumable product failed";
            throw err;
          }
          break;
        }
        case API_REPORT_CONTRACTS:
          {
            const { report } = await BoxappReportBackend.contractsReport(
              ...payload
            );
            if (report) {
              dispatch(success({ report: report }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get report failed";
              throw err;
            }
          }
          break;
        case API_REPORT_ORDERS:
          {
            const { report } = await BoxappReportBackend.ordersReport(
              ...payload
            );
            if (report) {
              dispatch(success({ report: report }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get report failed";
              throw err;
            }
          }
          break;
        case API_REPORT_MONTHLY:
          {
            const { report } = await BoxappReportBackend.monthlyReport(
              ...payload
            );
            if (report) {
              dispatch(success({ report: report }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              err.data.message = "get report failed";
              throw err;
            }
          }
          break;

        case API_GET_DASHBOARD_MONTHLY_REVENUE:
          {
            const { monthly_revenue } =
              await BoxappDashboardBackend.monthlyRevenue();
            if (monthly_revenue) {
              dispatch(success({ monthlyRevenue: monthly_revenue }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              // err.data.message = "get report failed"
              // throw (err)
            }
          }
          break;
        case API_GET_DASHBOARD_LATEST_ORDER_STATUS:
          {
            const { order_status } =
              await BoxappDashboardBackend.latestOrdersStatus();
            if (order_status) {
              dispatch(success({ orderStatus: order_status }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              // err.data.message = "get report failed"
              // throw (err)
            }
          }
          break;
        case API_GET_DASHBOARD_LATEST_MONTHLY_ORDER:
          {
            const { orders } = await BoxappDashboardBackend.latestMonthOrders();
            if (orders) {
              dispatch(success({ orders: orders }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              // err.data.message = "get report failed"
              // throw (err)
            }
          }
          break;
        case API_GET_DASHBOARD_LATEST_STORAGE_STATUS:
          {
            const { storage_status } =
              await BoxappDashboardBackend.latestStorageStatus();
            if (storage_status) {
              dispatch(success({ storageStatus: storage_status }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              // err.data.message = "get report failed"
              // throw (err)
            }
          }
          break;
        case API_GET_CUSTOM_FIELDS: {
          const { custom_fields } =
            await BoxappCustomFieldBackend.getCustomFields(...payload);
          if (custom_fields) {
            dispatch(success({ customFields: custom_fields }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "get custom fields failed";
            throw err;
          }
          break;
        }
        case API_CREATE_CUSTOM_FIELD: {
          const { custom_field } =
            await BoxappCustomFieldBackend.createCustomField(...payload);
          if (custom_field) {
            successMsg = "Create field Success";
            dispatch(success({ customField: custom_field }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "create custom fields failed";
            throw err;
          }
          break;
        }
        case API_UPDATE_CUSTOM_FIELD: {
          const { custom_field } =
            await BoxappCustomFieldBackend.updateCustomField(...payload);
          if (custom_field) {
            successMsg = "Update field Success";
            dispatch(success({ customField: custom_field }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "update custom fields failed";
            throw err;
          }
          break;
        }
        case API_GET_NOTIFICATIONS: {
          const { notifications } =
            await BoxappNotificationBackend.getNotifications(...payload);
          if (notifications) {
            dispatch(success({ notifications: notifications }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "update notifications failed";
            throw err;
          }
          break;
        }
        case API_UPDATE_NOTIFICATIONS: {
          const { notifications } =
            await BoxappNotificationBackend.updateNotifications(...payload);
          if (notifications) {
            dispatch(success({ notifications: notifications }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "update notifications failed";
            throw err;
          }
          break;
        }
        case API_GET_SETTINGS: {
          const { settings } = await BoxappAppSettingBackend.getAppSettings(
            ...payload
          );
          if (settings) {
            dispatch(success({ settings: settings }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "update settings failed";
            throw err;
          }
          break;
        }
        case API_SET_SETTINGS: {
          const { settings } = await BoxappAppSettingBackend.setAppSetting(
            ...payload
          );
          if (settings) {
            dispatch(success({ settings: settings }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            err.data.message = "update settings failed";
            throw err;
          }
          break;
        }
        default:
      }

      if (successMsg) {
        showModal(MODAL_TYPES.ALERT_MODAL, {
          title: successMsg,
          messageType: "success",
          message: successMsg,
        });
      }
    } catch (e) {
      APICallStack.pop();
      if (APICallStack.length === 0) {
        setLoading(false);
      }

      if (e) {
        console.log(e)
        if (e.status === 401) {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: "UnAuthorized",
            messageType: "failed",
            message: e.data.message,
            onConfirm: () => {
              history.push("/error-403");
            },
          });
          dispatch(error("permission denied"));
          // history.push("/error-403")
        } else if (e.status === 403) {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: "Permission Denied",
            messageType: "failed",
            message: "you have no permission, please login again.",
            onConfirm: () => {
              history.push("/error-403");
            },
          });
          dispatch(error("permission denied"));
          // history.push("/error-403")
        } else {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: `Error ${e?.data?.message ?? e}`,
            messageType: "failed",
            message: e?.data?.detail ?? e,
          });
        }
        // dispatch(error(e.data.message));
      } else {
        showModal(MODAL_TYPES.ALERT_MODAL, {
          title: "Unknown Error",
          messageType: "failed",
          message: e.data.message ?? "",
          onConfirm: () => {
            if (e.message === "Network Error") {
              history.push("/login");
            }
          },
        });
        // dispatch(error(e.message));
      }
    } finally {
      setTimeout(apiTimeOut, 10000, "");
    }
  }, []);

  return [state, makeRequest];
};
export default UseAPI;
