import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";

class BoxappScheduleBackend extends BoxappBackend {
  runSchedule = async (branchID, scheduleId) => {
    try {
      let request = {
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/schedule/${scheduleId}/run`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        // params: params,
      };

      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getSchedule = async (branchID, storageID, scheduleId) => {
    try {
      let request = {
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${storageID}/schedule/${scheduleId}`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        // params: params,
      };

      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  getSchedules = async (branchID, params) => {
    try {
      let request = {
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/schedules`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: params,
      };

      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  cancelSchedule = async (branchID, scheduleID, params) => {
    try {
      let request = {
        method: "delete",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/schedule/${scheduleID}`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: params,
      };

      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappScheduleBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappScheduleBackend = () => {
  if (!_BoxappScheduleBackend) {
    _BoxappScheduleBackend = new BoxappScheduleBackend();
  }
  return _BoxappScheduleBackend;
};

export { getBoxappScheduleBackend };
