
import axios from 'axios';
import { BoxappBackend } from './boxapp_helper'

class BoxappOrderItemBackend extends BoxappBackend {

  /**
  /**
   * 
   * @param {*} error 
   * @returns 
   */

  getOrderItems = async (branchID, id, param) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/order-items`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: param
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  createOrderItem = async (branchID, id, param) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/order-items`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: param
      });
      if (!(response.status >= 200 && response.status < 300)) {
        const error = new Error(response.data.message);
        error.response = response;

        throw error;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };


}

let _BoxappOrderItemBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappOrderItemBackend = () => {
  if (!_BoxappOrderItemBackend) {
    _BoxappOrderItemBackend = new BoxappOrderItemBackend();
  }
  return _BoxappOrderItemBackend;
};

export { getBoxappOrderItemBackend };
