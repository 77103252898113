import axios from 'axios';
require('dotenv').config()
export class BoxappBackend {

  constructor() {
    this.baseURL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_SERVER : "/api"
  }
  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}
let _BoxappBackend = null;
const getBoxappBackend = () => {
  if (!_BoxappBackend) {
    _BoxappBackend = new BoxappBackend();
  }
  return _BoxappBackend;
};


export { getBoxappBackend };