import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next"
import shopTranslationENG from './locales/shop/en/translation.json';
import shopTranslationZH from './locales/shop/zh/translation.json';
import roomTranslationENG from './locales/room/en/translation.json';
import roomTranslationZH from './locales/room/zh/translation.json';
import miniStoreTranslationENG from './locales/mini-store/en/translation.json';
import miniStoreTranslationZH from './locales/mini-store/zh/translation.json';
// the translations 
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        "shop": shopTranslationENG,
        "room": roomTranslationENG,
        "mini-store": miniStoreTranslationENG
      },
      zh: {
        "shop": shopTranslationZH,
        "room": roomTranslationZH,
        "mini-store": miniStoreTranslationZH,
      }
    },
    defaultNS: localStorage.getItem("app-namespace") ?? "shop",
    fallbackLng: "zh", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    supportedLngs: ['zh', 'en'],
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;