import { set } from "immutable";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import "./spinner.scss";
const Spinners = (props) => {
  const [spinner, setSpinner] = useState(props.loading);
  useEffect(() => {
    const interval = props.interval ? props.interval : 3000
    const timeoutId = setTimeout(() => {
      setSpinner(false)

      if (props.onFinish) {
        props.onFinish()
      }
    }, interval)
    return function cleanup() {
      clearTimeout(timeoutId);
    }

  }, []);

  useEffect(() => {
    setSpinner(props.loading)
  }, [props.loading])


  return spinner ? (
    <React.Fragment>
      <div className={`spinner ${props.className}`}>
        <span>  {props.t("Loading")}</span>
        <div className="spinner-grow text-primary m-1" role="status">

        </div>
        <div className="spinner-grow text-primary m-1" role="status">

        </div>
        <div className="spinner-grow text-primary m-1" role="status">

        </div>
        <div className="spinner-grow text-primary m-1" role="status">

        </div>
        <div className="spinner-grow text-primary m-1" role="status">

        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className={`spinner ${props.className}`}>
        {props.children}
      </div>
    </React.Fragment>
  )
};
export default withTranslation()(Spinners)