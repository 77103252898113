import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import "./storage.scss";
import { withTranslation } from 'react-i18next';
import PaymentInfo from "../../../pages/Orders/components/paymentInfo";

const StoragePaymentPanel = (props) => {

  return (
    <Card  >
      {props.titles?.length > 0 &&
        <CardTitle>
          {props.titles?.map((title, i) => {
            return (
              <span key={i}
                className={`font-weight-bold h5 ${i === props.activeStep ? "" : "text-secondary"}`}>
                {props.t("Step " + i) + "-" + title}
                {i < props.titles.length - 1 && <i className="bx bx-right-arrow-alt ml-4 font-size-16 align-middle "></i>}
              </span>
            )

          })}
        </CardTitle>}
      <CardBody>
        <PaymentInfo
          mode={props.mode}
          customer={props.order?.customer}
          order={props.order}
          invoice={props.invoice}
          onSuccess={props.onSuccess}
          onClose={props.onClose}
        />
      </CardBody>
    </Card>
  )

};


export default withRouter(withTranslation()(StoragePaymentPanel));
