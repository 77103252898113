
import axios from 'axios';
import { BoxappBackend } from './boxapp_helper'

class BoxappBranchBackend extends BoxappBackend {

  createBranch = async (branch) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/branches",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: branch
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  disableBranch = async (id, disable) => {
    try {
      const response = await axios({
        method: 'put',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/branch/" + id + "/disable",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: {
          disable: disable
        }
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  
  sortBranch = async (id, sort) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/branch/" + id+"/sort",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: {
          sort:sort
        }
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  updateBranch = async (id, branch) => {
    try {
      const response = await axios({
        method: 'put',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/branch/" + id,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: branch
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  updateBranchTandC = async (id, payload) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/branch/" + id + "/tandc",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: payload
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getBranches = async () => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/branches",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getBranch = async (id) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/branch/" + id,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  uploadBranchLogo = async (branchID, payload) => {
    try {
      const formData = new FormData();
      formData.append('file', payload.file)

      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/logo`,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        data: formData,
        validateStatus: function (status_1) {
          return status_1;
        },

      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappBranchBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappBranchBackend = () => {
  if (!_BoxappBranchBackend) {
    _BoxappBranchBackend = new BoxappBranchBackend();
  }
  return _BoxappBranchBackend;
};

export { getBoxappBranchBackend };
