
import axios from 'axios';
import { BoxappBackend } from './boxapp_helper'

class BoxappOrderFollowBackend extends BoxappBackend {

  /**
  /**
   * 
   * @param {*} error 
   * @returns 
   */

  getBranchOrdersFollows = async (branchID, filter) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/orders/follows`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: filter
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getOrderFollows = async (branchID, orderID) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${orderID}/follows`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },

      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  createOrderFollow = async (branchID, orderID, follow) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${orderID}/follows`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: follow
      });
      if (!(response.status >= 200 && response.status < 300)) {
        const error = new Error(response.data.message);
        error.response = response;

        throw error;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

}

let _BoxappOrderFollowBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappOrderFollowBackend = () => {
  if (!_BoxappOrderFollowBackend) {
    _BoxappOrderFollowBackend = new BoxappOrderFollowBackend();
  }
  return _BoxappOrderFollowBackend;
};

export { getBoxappOrderFollowBackend };
