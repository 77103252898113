import React, { createContext, useContext, useState } from "react";

import store from "../util/storage"
import duration from "../util/duration"
import { getBoxappBranchBackend } from "../helpers/boxapp_branch_helper";
export const BranchContext = createContext();
export const useBranchContext = () => useContext(BranchContext);
// This context provider is passed to any component requiring the context
const BranchProvider = ({ children }) => {
  //api helper 
  const [contextCompany, setContextCompany] = useState(null);
  const [contextBranch, setContextBranch] = useState(null);
  const [contextBranches, setContextBranches] = useState(store.getWithExpiry('branches')?.data);

  return (
    <BranchContext.Provider
      value={{
        contextCompany,
        setContextCompany,
        contextBranch,
        setContextBranch,
        contextBranches,
        setContextBranches,
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};
export default BranchProvider;