import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useGlobalModalContext } from "../../context/GlobalModalContext";
import { withTranslation } from 'react-i18next';

const RemovalActionModal = (props) => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, onConfirm, onCancel } = modalProps || {};
  const [delMessage, setDelMessage] = useState("")
  const handleModalToggle = () => {

    hideModal();
    if (onCancel) {
      onCancel()
    }
  };

  const handleModalActionConfirm = (e) => {
    if (delMessage === "UNDERSTAND") {
      hideModal();
      if (onConfirm) {
        onConfirm(e)
      }
    }
  }

  return (
    <SweetAlert
      title={props.t(title)}
      warning
      showCancel
      show={true}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      confirmBtnText={props.t("OK")}
      cancelBtnText={props.t("CANCEL")}
      onConfirm={handleModalActionConfirm}
      onCancel={() => handleModalToggle()}
    >
      {props.t("Please Type")} [<b>UNDERSTAND</b>]

      <input
        type="text"
        className="form-control mb-2"
        onChange={(e) => { setDelMessage(e.target.value) }}
      />
    </SweetAlert>)
};

export default withTranslation()(RemovalActionModal);
