import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { Offsymbol, OnSymbol } from "../../Switch/switchButton"
import Switch from "react-switch";
import { BxButton } from "../../Button"

import { Link, withRouter } from 'react-router-dom';
import { AvForm } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "./storage.scss";
// Redux


import {
  useGlobalModalContext,
  MODAL_TYPES,
} from "../../../context/GlobalModalContext";

import { useBranchContext } from "../../../context/BranchProviderContext";
import { useStorageContext } from "../../../context/StorageProviderContext";
import UseAPI, { SUCCESS, API_TERMINATE_STORAGE } from "../../../api";
import { getPaymentItems } from '../../../resolver/orders/index';

const StorageTerminate = (props) => {
  const { contextStorage } =
    useStorageContext();

  const { showModal } = useGlobalModalContext();
  const [terminateDate, setTerminateDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");
  const [{ status, response }, makeRequest] = UseAPI();
  const { contextBranch } = useBranchContext();

  const togglemodal = () => {
    setTerminateDate(new Date());
    setRemarks("");
    props.onClose();
  };

  useEffect(() => {
    if (status === SUCCESS) {
      if (response.storage) {
        props.onSuccess(props.mode, response.storage);
      }
    }
  }, [status, response])

  const submitRent = () => {
    if (!props.customer) {
      return;
    }
    showModal(MODAL_TYPES.ACTION_MODAL, {
      title: props.t('Are you Confirm?'),
      message: props.t(`The termination cannot resume`),
      onConfirm: () => {
        showModal(MODAL_TYPES.REMOVAL_ACTION_MODAL, {
          title: props.t('Confirm to terminate'),
          onConfirm: () => {
            terminate();
          },
        });
      },
    });
  };

  const terminate = () => {
    if (!terminateDate) {
      return
    }
    makeRequest(API_TERMINATE_STORAGE,
      contextBranch?.id,
      contextStorage?.id,
      {
        remarks: remarks,
        customer_id: props.customer.id,
        termination_date: moment(terminateDate).startOf('day').unix(),
      }
    );
  };

  useEffect(() => {
    if (props.order) {
      setRemarks(props.order ? props.order.remarks : '');
    }
  }, [props.order])


  return !props.order ? null : (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className=""
        tabIndex="-1"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>{props.t('Contract Terminate')}</ModalHeader>
          <ModalBody>

            <AvForm action="#" onSubmit={submitRent}>
              <Card outline color="dark" className="border">
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Label>{props.t('Storage')} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p >
                            {contextStorage?.code}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Label>{props.t('Order No')} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p >
                            {props.order.order_no}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Label>{props.t('Customer')} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p >
                            {props.customer?.first_name + props.customer?.last_name}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Label>{props.t('Contract No')} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p >
                            {props.order.contract_id}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <Row>
                        <Col>
                          <Label>{props.t('Deposit')} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {props.depositOrder?.order_no ?? "-"}



                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {props.depositOrder &&
                    <Alert
                      color="danger"
                      className="alert-dismissible fade show  mb-4 pt- font-size-13"
                      role="alert"
                    >
                      <i className="mdi mdi-alert">
                        <Label><a data-tip data-for='tips1'>{props.t('Tipsⓘ')}</a></Label>

                        <Row>
                          <Col xs="6">
                            <Row>
                              <Col>
                                <Label>{`${props.t('Deposit Total Amount')}:`}</Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p >
                                  ${props.depositOrder?.invoices?.filter(
                                    (invoice) => invoice?.status === "COMPLETED" && invoice.invoice_type === "Payment"
                                  )?.flatMap((invoice) => {
                                    return getPaymentItems(invoice)
                                  })?.reduce((a, paymentItems) => {
                                    return a + Number(paymentItems?.amount)
                                  }, 0).toFixed(2)}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="6">
                            <Row>
                              <Col>
                                <Label>{`${props.t('Deposit Total Refund')}:`}</Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p >
                                  ${props.depositOrder?.invoices?.filter(
                                    (invoice) => invoice?.status === "COMPLETED" && invoice.invoice_type === "Refund"
                                  )?.flatMap((invoice) => {
                                    return getPaymentItems(invoice)
                                  })?.reduce((a, paymentItems) => {
                                    return a + Number(paymentItems?.amount)
                                  }, 0).toFixed(2) ?? 0}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>


                        <Row>
                          <Col xs="6">
                            <Row>
                              <Col>
                                <Label><b>{`${props.t('Deposit Total Remain')}:`}</b></Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p >
                                  <b><u>${props.depositOrder?.invoices?.filter(
                                    (invoice) => invoice?.status === "COMPLETED"
                                  )?.flatMap((invoice) => {
                                    return getPaymentItems(invoice)
                                  })?.reduce((a, paymentItems) => {
                                    return a + Number(paymentItems?.amount)
                                  }, 0).toFixed(2) ?? 0}</u></b>
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="6">
                            {
                              (props.depositOrder?.invoices?.filter(
                                (invoice) => invoice?.status === "COMPLETED"
                              )?.flatMap((invoice) => {
                                return getPaymentItems(invoice)
                              })?.reduce((a, paymentItems) => {
                                return a + Number(paymentItems?.amount)
                              }, 0).toFixed(2) > 0)
                              &&
                              <Row>
                                <Col>
                                  {props.depositOrder && (<a href={`/branch/${props.depositOrder.branch_id}/order/${props.depositOrder.id}?orderType=Deposit&tab=2`}
                                    className="font-size-12 font-italic font-weight-bold secondary text-decoration-underline">
                                    {props.t("refund now")}<i className="bx bx-question-mark" style={{ cursor: "pointer" }}></i>
                                    <p> {props.t("Will direct to the Deposit Details")}</p>
                                  </a>)}
                                </Col>
                              </Row>}
                            <Col>


                            </Col>
                          </Col>
                        </Row>





                        <ReactTooltip id='tips1' effect='solid'>

                          <span>
                            <p>{props.t('Deposit can be refunded anytime if the Deposit Status not yet be CLOSED.')}</p></span>
                        </ReactTooltip>
                      </i>
                    </Alert>
                  }
                  <Row>
                    <Col xs="6">
                      <Row>
                        <Col xs="12" className="mt-3">
                          <Label>{props.t('Order Start Date')} </Label>
                          <p >
                            {moment
                              .unix(props.order.actual_start_date)
                              .format("YYYY/MM/DD")}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col xs="12" className="mt-3">
                          <Label>{props.t('Order End Date')} </Label>
                          <p >
                            {props.order.actual_end_date
                              ? moment
                                .unix(props.order.actual_end_date)
                                .format("YYYY/MM/DD")
                              : ""}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>


                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label>{props.t('Terminate At')}</Label>
                      <InputGroup>
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          name="terminateDate"
                          selected={terminateDate}
                          className="form-control"
                          onChange={(date) => setTerminateDate(date)}
                          showYearDropdown
                          minDate={moment
                            .unix(props.order.actual_start_date).diff(moment()) < 0 ? new Date() :
                            moment
                              .unix(props.order.actual_start_date).toDate()}
                          maxDate={moment
                            .unix(props.order.actual_end_date).toDate()}
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" className="mt-3">
                      <FormGroup >
                        <Label>{props.t('Remarks')}</Label>
                        <Input
                          type="textarea"
                          maxLength="500"
                          name="remarks"
                          onChange={(e) => setRemarks(e.target.value)}
                          value={remarks}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <BxButton type="submit" color="success">
                    {props.t('Submit')}
                  </BxButton>
                  <Button type="button" color="secondary" onClick={togglemodal}>
                    {props.t('Close')}
                  </Button>

                </CardBody>
              </Card>
            </AvForm>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal >
    </React.Fragment >
  );
};

export default withRouter(withTranslation()(StorageTerminate));
