import React, { useState, useEffect } from "react";
import "./storageSearch.scss"
import { useStorageContext } from "../../../context/StorageProviderContext";
import StorageTabs from "./StorageTabs";
import { withTranslation } from 'react-i18next';
import {
  ModalHeader,
  Modal,
  ModalBody,
  Col,
  Row,
  Card,
  CardBody,
  Button
} from "reactstrap";

import { withRouter } from "react-router-dom";

import UseAPI, { SUCCESS, API_GET_STORAGES } from "../../../api";


const StorageSearch = (props) => {

  const [category, setCategory] = useState([]);
  const [storages, setStorages] = useState([]);
  const [fStorages, setFstorages] = useState([]);
  const [{ status, response }, makeRequest] = UseAPI();


  useEffect(() => {
    if (status === SUCCESS) {
      if (response.storages) {
        setStorages(response.storages.data);
      }
    }
  }, [status, response])

  const togglemodal = () => {
    setCategory([]);
    setStorages([])
    setFstorages([])
    props.onClose();
  };

  useEffect(() => {
    let fStorages = []
    if (category) {
      fStorages = storages.filter((storage) => {
        if (storage.categories.find(c => c.id === category.id)) {
          return storage
        }
      })
    } else {
      fStorages = storages
    }

    setFstorages(fStorages)
  }, [category, storages])

  useEffect(() => {
    if (props.modal) {
      makeRequest(API_GET_STORAGES, props.branchId)
    }
  }, [props.modal]);

  const selectStorage = (selectedStorage) => {
    props.onSearched(selectedStorage);
  };

  return (
    <Modal

      isOpen={props.modal}
      role="dialog"
      backdrop="static"
      autoFocus={true}
      centered={true}
      className="searchModal"
      tabIndex="-1"
      toggle={togglemodal}
    >
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>{props.t('Search Storages')}</ModalHeader>
        <ModalBody>
          <Row className="mt-2">
            <Col sm="12">
              <Card>
                <CardBody className="content-column">
                  <StorageTabs
                    t={props.t}
                    onSelectedCategory={(cat) => {
                      setCategory(cat)
                    }}
                  >
                    <Row className="mt-2">
                      <StorageGrid
                        t={props.t}
                        search={true}
                        storages={fStorages}
                        onSelected={selectStorage}
                      />
                    </Row>
                  </StorageTabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  );
};

const getStorageColor = (storage) => {
  // eslint-disable-next-line default-case
  switch (storage.status) {
    case "RENT":
      if (storage.rent_type === "YEAR") {
        return "primary";
      } else if (storage.rent_type === "MONTH") {
        return "info";
      }
      return "success";
    case "PENDING":
      return "warning";
    case "AVAILABLE":
      return "light";
    case "EXPIRED":
      return "danger";
    case "DISABLE":
      return "dark";
    default:
      return "";
  }
};
const StorageGrid = (props) => {
  const { contextStorage, setContextStorage } = useStorageContext();

  const disable = (storage) => {
    if (props.search) {
      if (storage.status !== "AVAILABLE") {
        return true;
      }
    }
    return false;
  };
  return !props.storages ? null : (
    <Row>
      {props.storages.sort((a, b) => a.code - b.code).map((storage, i) => {
        return (
          <Col
            key={i}
            xs={3}
            sm={2}
            md={contextStorage ? 2 : 1}
            className={`mt-2 mr-xs-1 mr-md-2`}
          >
            <span hidden={!storage.schedule_id} className={`schedule badge badge-danger`}>  </span>
            <Button
              color={getStorageColor(storage)}
              className={` btn waves-effect gridItem`}
              disabled={disable(storage)}
              onClick={() => {
                if (props.onSelected) {
                  props.onSelected(storage);
                } else {
                  setContextStorage(storage);
                }
              }}
            >
              <span>{storage.code}</span>
            </Button>
          </Col>
        );
      })}
    </Row>
  );
};

export default withRouter(withTranslation()(StorageSearch));
