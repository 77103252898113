
import axios from 'axios';
import { BoxappBackend } from './boxapp_helper'

class BoxappOrderBackend extends BoxappBackend {
  //deposit
  createDepositOrder = async (branchID, depositOrder) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/orders?orderType=Deposit`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: depositOrder,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  //order
  createOrder = async (branchID, order) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/orders?orderType=Rent`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: order,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  getOrders = async (query) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/orders",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: query
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getOrder = async (branchID, id, orderType) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}?orderType=${orderType ?? ""}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {}
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  getOrderEvents = async (branchID, id, query) => {
    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/events`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: query
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  updateOrder = async (branchID, id, orderType, order) => {
    try {
      const response = await axios({
        method: 'put',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}?orderType=${orderType ?? ""}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: order
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  // createOrder = async (order) => {
  //   try {
  //     const response = await axios({
  //       method: 'post',
  //      baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
  //       url: "orders/create",
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json; charset=utf-8',
  //         'Authorization': 'Bearer ' + localStorage.getItem('token')
  //       },
  //       'Content-Type': 'application/json',
  //       validateStatus: function (status_1) {
  //         return status_1;
  //       },
  //       data: order
  //     });
  //     if (!(response.status >= 200 && response.status < 300)) {
  //       const error = new Error(response.data.message);
  //       error.response = response;

  //       throw error;
  //     }
  //     return response.data.data;
  //   } catch (err) {
  //     throw err;
  //   }
  // };
  createPayment = async (branchID, orderId, invoiceId, payment) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${orderId}/invoice/${invoiceId}/payments`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: payment
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  uploadOrderAttachment = async (branchID, id, payload) => {
    try {
      const formData = new FormData();
      formData.append('file', payload.file)
      formData.append('orderType', payload.orderType)

      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/attachments`,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        data: formData,
        validateStatus: function (status_1) {
          return status_1;
        },

      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getOrderAttachment = async (attachmentID) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/attachment/${attachmentID}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        responseType: 'blob',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {}
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  getOrderAttachments = async (branchID, id, orderType) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/attachments?orderType=${orderType}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getReceipt = async (branchID, id, receiptType, lng) => {
    try {
      const response = await axios({
        method: 'get',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/receipt`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        responseType: 'arraybuffer',
        validateStatus: function (status_1) {
          return status_1;
        },
        params: { receipt_type: receiptType, language: lng }
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  };
  void = async (branchID, id, order) => {
    try {
      const response = await axios({
        method: 'post',
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/order/${id}/void`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        'Content-Type': 'application/json',
        validateStatus: function (status_1) {
          return status_1;
        },
        data: order,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

}

let _BoxappOrderBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappOrderBackend = () => {
  if (!_BoxappOrderBackend) {
    _BoxappOrderBackend = new BoxappOrderBackend();
  }
  return _BoxappOrderBackend;
};

export { getBoxappOrderBackend };
