import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";

class BoxappStorageBackend extends BoxappBackend {
  getStorages = async (branchID, params) => {
    try {
      let request = {
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storages`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: params,
      };

      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getStorage = async (branchID, id) => {
    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/storage/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getStorageRentRecords = async (branchID, id) => {
    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/storage/${id}/rent/records`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getStorageEvents = async (branchID, id) => {
    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/storage/${id}/events`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getStorageOrders = async (branchID, id) => {
    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/storage/${id}/orders`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  createStorage = async (branchID, storage) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/storages`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: storage,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  updateStorage = async (branchID, id, storage) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/storage/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: storage,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  bulkUpdateStorage = async (branchID, storages) => {
    try {
      const response = await axios({
        method: "put",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/branch/${branchID}/storages`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: storages,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  createCategories = async (category) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: "/categories",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: category,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  rentStorage = async (branchID, id, rent) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/rent`,
        validateStatus: function (status_1) {
          return status_1;
        },
        data: rent,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  confirmRentStorage = async (branchID, id) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/rent/confirm`,
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  extendRentStorage = async (branchID, id, rent) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

        url: `/branch/${branchID}/storage/${id}/extend`,
        validateStatus: function (status_1) {
          return status_1;
        },
        data: rent,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  transferRentStorage = async (branchID, id, transferInfo) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/transfer`,
        validateStatus: function (status_1) {
          return status_1;
        },
        data: transferInfo,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  terminateRentStorage = async (branchID, id, rent) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/terminate`,
        validateStatus: function (status_1) {
          return status_1;
        },
        data: rent,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  maintenanceStorage = async (branchID, id) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/maintenance`,
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  releaseStorage = async (branchID, id, data) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/release`,
        validateStatus: function (status_1) {
          return status_1;
        },
        data
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  resumeStorage = async (branchID, id) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/resume`,
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  restoreStorage = async (branchID, id) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/restore`,
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  closeStorage = async (branchID, id) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/close`,
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  donateStorageItem = async (branchID, id) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/branch/${branchID}/storage/${id}/donate`,
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  cancelSchedule = async (branchID, id) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

        url: `/branch/${branchID}/storage/${id}/cancelSchedule`,
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  cancel = async (branchID, id) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

        url: `/branch/${branchID}/storage/${id}/cancel`,
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappStorageBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappStorageBackend = () => {
  if (!_BoxappStorageBackend) {
    _BoxappStorageBackend = new BoxappStorageBackend();
  }
  return _BoxappStorageBackend;
};

export { getBoxappStorageBackend };
