import axios from "axios";
import StarRatings from "react-star-ratings";
import { BoxappBackend } from "./boxapp_helper";

class BoxappReportBackend extends BoxappBackend {
  storagesReport = async (params) => {
    try {
      const request = {
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/reports/storages`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {
          ...params,
          branches: undefined,

        }
      };

      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  ordersReport = async (params) => {
    try {
      let request = {
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/reports/orders`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {
          ...params,
          branches: undefined,

        }
      };
      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  contractsReport = async (params) => {
    try {
      let request = {
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/reports/contracts`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {
          ...params,
          branches: undefined,

        }
      };
      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  monthlyReport = async (params) => {
    try {
      let request = {
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/reports/monthly`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: {
          ...params,
          branches: undefined,
        }
      };
      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappReportBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappReportBackend = () => {
  if (!_BoxappReportBackend) {
    _BoxappReportBackend = new BoxappReportBackend();
  }
  return _BoxappReportBackend;
};

export { getBoxappReportBackend };
