import React, { lazy, Suspense, useMemo } from "react";
import ReactDOM from "react-dom";
// import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import GlobalModalProvider from "./context/GlobalModalContext";
import AuthProvider from "./context/AuthProviderContext";
import BranchProvider from "./context/BranchProviderContext";
import CustomerProvider from "./context/CustomerProviderContext";
import StorageProvider from "./context/StorageProviderContext";
import moment from "moment-timezone"

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};
initializeApp(firebaseConfig);

const App = lazy(() => import('./App'));

const app = (
  <GlobalModalProvider>
    <AuthProvider>
      <CustomerProvider>
        <BranchProvider>
          <BrowserRouter>
            <StorageProvider>

              <Suspense fallback={<div>loading...</div>}>
                <App />
              </Suspense>

            </StorageProvider>
          </BrowserRouter>
        </BranchProvider>
      </CustomerProvider>
    </AuthProvider>
  </GlobalModalProvider>
);
moment.tz.setDefault("Asia/Hong_Kong");

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
